import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import {
  useAddUserMutation,
  useEditUserMutation,
  useGetUserPermissionsDropdownMutation,
  useLazyGetUserQuery,
  useLazyGetActiveCompanyTypeQuery,
  useGetContactMutation,
} from '../../../services'
import { isUserDropdownEmpty, setUserDropdown } from '../../../redux/slices/userDropdownSlice'
import { Form } from 'react-final-form'
import { Header } from './components/Header'
import { NameForm } from './components/NameForm'
import { AddressForm } from './components/AddressForm'
import { ContactForm } from './components/ContactForm'
import { IdentityForm } from './components/IdentityForm'
import { UserSettingsForm } from './components/UserSettingsForm'
import { SalesDepartmentForm } from './components/SalesDepartmentForm'
import { FinanceDepartmentForm } from './components/FinanceDepartmentForm'
import { ServiceDepartmentForm } from './components/ServiceDepartmentForm'
import { AddressData, UspContactPhoneData } from '../../../models'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardHeader, Container, generateUtilityClasses } from '@mui/material'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import validateForm from '../../../utils/validateForm'
import { userValidation } from '../../../validation'
import { CustomCircular } from '../../../ui'
import { convertDateToString, convertStringToDate } from '../../../utils/dateUtils'
import { appRoute } from '../../../constants'
import removeMask from '../../../utils/removeMask'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('Box', ['root', 'firstRow', 'secondRow', 'buttonWrap', 'saveBtn', 'pageBody'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.firstRow}`]: {
    minHeight: '300px',
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.secondRow}`]: {
    minHeight: '360px',
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

export const AddEditUser = () => {
  const { userId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isDropdownEmpty = useSelector(isUserDropdownEmpty)
  const [formValues, setFormValues] = useState<any>({})
  const [contactID, setContactID] = useState<number | undefined>(undefined)
  const [addressID, setAddressId] = useState<number | undefined>()
  const [contactPhoneID, setContactPhoneID] = useState<number | null | undefined>()
  const [initialValues, setInitialValues] = useState({})
  const [isInvitationAccepted, setIsInvitationAccepted] = useState<boolean>(true)

  const [getUser, { data: userData, isSuccess: getUserIsSuccess, isLoading: getUserIsLoading }] = useLazyGetUserQuery()
  const [getContact, { data: contactData, isSuccess: getContactIsSuccess, isLoading: getContactIsLoading, reset }] = useGetContactMutation()
  const [addUser, { isSuccess: addUserIsSuccess, isLoading: addUserIsLoading }] = useAddUserMutation()
  const [editUser, { isSuccess: editUserIsSuccess, isLoading: editUserIsLoading }] = useEditUserMutation()
  const [getActiveCompanyTypes, { data: activeCompanyTypesData }] = useLazyGetActiveCompanyTypeQuery()

  const [
    userPermissionsDropdown,
    { data: userPermissionsDropdownData, isSuccess: userPermissionsDropdownIsSuccess, isLoading: userPermissionsDropdownIsLoading },
  ] = useGetUserPermissionsDropdownMutation()
  useEffect(() => {
    if (isDropdownEmpty) {
      // check if empty
      userPermissionsDropdown({ userPermissionsDropdown: {} })
    }
    if (userId !== 'add') {
      // is edit page
      getUser({ userId: userId as string })
    } else {
      setInitialValues({
        accountType: 'individual',
        PhoneType: 'Cell',
        isAllowedAccessSales: false,
        isAllowedAccessFinance: false,
        isAllowedAccessService: false,
        isActive: true,
      })
    }
    return () => setInitialValues({})
  }, [userId, userData])

  useEffect(() => {
    getActiveCompanyTypes({})
  }, [])

  useEffect(() => {
    if (userPermissionsDropdownIsSuccess) {
      dispatch(setUserDropdown(userPermissionsDropdownData))
    }
  }, [userPermissionsDropdownIsSuccess])

  const handleSubmit = (value: any) => {
    setFormValues(value)
    const uspUserAddEdit = {
      userCode: value?.userCode?.trim(),
      username: value?.username?.trim(),
      contactID: contactID as number,
      isAllowedViewEmployeeAccounts: value?.isAllowedViewEmployeeAccounts,
      isAllowedAccessSales: value?.isAllowedAccessSales,
      isAllowedAccessFinance: value?.isAllowedAccessFinance,
      isAllowedAccessService: value?.isAllowedAccessService,
      salesBranchID: value?.salesBranchID,
      financeBranchID: value?.financeBranchID,
      serviceBranchID: value?.serviceBranchID,
      salesRoleID: value?.salesRoleID,
      financeRoleID: value?.financeRoleID,
      serviceRoleID: value?.serviceRoleID,
      isRestrictedToSalesBranch: value?.isRestrictedToSalesBranch,
      restrictedToSalesCompanyID: value?.isRestrictedToSalesBranch ? null : value?.restrictedToSalesCompanyID || null,
      isRestrictedToFinanceBranch: value?.isRestrictedToFinanceBranch,
      restrictedToFinanceCompanyID: value?.isRestrictedToFinanceBranch ? null : value?.restrictedToFinanceCompanyID || null,
      isRestrictedToServiceBranch: value?.isRestrictedToServiceBranch,
      restrictedToServiceCompanyID: value?.isRestrictedToServiceBranch ? null : value?.restrictedToServiceCompanyID || null,
      isRestrictedByIPAddress: value?.isRestrictedByIPAddress,
      isActive: value?.isActive,
      title: value?.Title,
      firstName: value?.accountType === 'individual' ? value?.firstName?.trim() : null,
      middleName: value?.accountType === 'individual' ? value?.middleName?.trim() : null,
      lastName: value?.accountType === 'individual' ? value?.lastName?.trim() : null,
      suffix: value?.accountType === 'individual' ? value?.suffix?.trim() : null,
      nickName: value?.accountType === 'individual' ? value?.nickName?.trim() : null,
      businessName: value?.accountType === 'business' ? value?.businessName?.trim() : null,
      dba: value?.accountType === 'business' ? value?.dba?.trim() : null,
      dateOfBirth: convertDateToString(value?.dateOfBirth),
      taxIDNumber: value?.taxIDNumber,
      email: value?.email,
      isContactUpdated: true,
      addressID: addressID?.toString() as string,
      address1: value?.Address1,
      address2: value?.Address2,
      city: value?.City,
      state: value?.State,
      postalCode: value?.PostalCode,
      county: value?.County,
      addressType: value?.AddressType,
      isPrimaryAddress: true,
      isAddressActive: true,
      isAddressUpdated: true,
      isPhoneUpdated: true,
      jsonPhoneData: [
        {
          ContactPhoneID: contactPhoneID as number,
          PhoneType: value?.PhoneType || 'Cell',
          PhoneNumber: removeMask(value.PhoneNumber) as string,
          PhoneExtension: value?.PhoneExtension || null,
          OrderOfPreference: 1,
          IsPreferred: true,
          IsPrimary: true,
          IsAllowCall: true,
          IsAllowSMS: true,
          IsActive: true,
        },
      ],
    }

    if (userId === 'add') {
      addUser({
        uspUserAddEdit: uspUserAddEdit,
      })

      // addUser({
      //   uspUserAddEdit: {
      //     userCode: value?.userCode?.trim(),
      //     username: value?.username?.trim(),
      //     contactID: null, // FIX
      //     isAllowedViewEmployeeAccounts: value?.isAllowedViewEmployeeAccounts,
      //     isAllowedAccessSales: value?.isAllowedAccessSales,
      //     isAllowedAccessFinance: value?.isAllowedAccessFinance,
      //     isAllowedAccessService: value?.isAllowedAccessService,
      //     salesBranchID: value?.salesBranchID,
      //     financeBranchID: value?.financeBranchID,
      //     serviceBranchID: value?.serviceBranchID,
      //     salesRoleID: value?.salesRoleID,
      //     financeRoleID: value?.financeRoleID,
      //     serviceRoleID: value?.serviceRoleID,
      //     isRestrictedToSalesBranch: value?.isRestrictedToSalesBranch,
      //     restrictedToSalesCompanyID: value?.isRestrictedToSalesBranch ? null : value?.restrictedToSalesCompanyID || null,
      //     isRestrictedToFinanceBranch: value?.isRestrictedToFinanceBranch,
      //     restrictedToFinanceCompanyID: value?.isRestrictedToFinanceBranch ? null : value?.restrictedToFinanceCompanyID || null,
      //     isRestrictedToServiceBranch: value?.isRestrictedToServiceBranch,
      //     restrictedToServiceCompanyID: value?.isRestrictedToServiceBranch ? null : value?.restrictedToServiceCompanyID || null,
      //     isRestrictedByIPAddress: value?.isRestrictedByIPAddress,
      //     isActive: value?.isActive,
      //     title: value?.Title,
      //     firstName: value?.accountType === 'individual' ? value?.firstName?.trim() : null,
      //     middleName: value?.accountType === 'individual' ? value?.middleName?.trim() : null,
      //     lastName: value?.accountType === 'individual' ? value?.lastName?.trim() : null,
      //     suffix: value?.accountType === 'individual' ? value?.suffix?.trim() : null,
      //     nickName: value?.accountType === 'individual' ? value?.nickName?.trim() : null,
      //     businessName: value?.accountType === 'business' ? value?.businessName?.trim() : null,
      //     dba: value?.dba,
      //     dateOfBirth: convertDateToString(value?.dateOfBirth),
      //     taxIDNumber: removeMask(value?.taxIDNumber),
      //     email: value?.email?.trim(),
      //     isContactUpdated: true,
      //     addressID: addressID?.toString() as string,
      //     address1: value?.Address1,
      //     address2: value?.Address2,
      //     city: value?.City,
      //     state: value?.State,
      //     postalCode: value?.PostalCode,
      //     county: value?.County,
      //     addressType: value?.AddressType,
      //     isPrimaryAddress: true,
      //     isAddressActive: true,
      //     isAddressUpdated: true,
      //     isPhoneUpdated: true,
      //     jsonPhoneData: [
      //       {
      //         ContactPhoneID: contactPhoneID as number,
      //         PhoneType: value?.PhoneType,
      //         PhoneNumber: removeMask(value.PhoneNumber) as string,
      //         PhoneExtension: value?.PhoneExtension || null,
      //         OrderOfPreference: 1,
      //         IsPreferred: true,
      //         IsPrimary: true,
      //         IsAllowCall: true,
      //         IsAllowSMS: true,
      //         IsActive: true,
      //       },
      //     ],
      //   },
      // })
    } else {
      // const uspUserAddEdit = {
      //   userCode: value?.userCode?.trim(),
      //   username: value?.username?.trim(),
      //   contactID: contactID as number,
      //   isAllowedViewEmployeeAccounts: value?.isAllowedViewEmployeeAccounts,
      //   isAllowedAccessSales: value?.isAllowedAccessSales,
      //   isAllowedAccessFinance: value?.isAllowedAccessFinance,
      //   isAllowedAccessService: value?.isAllowedAccessService,
      //   salesBranchID: value?.salesBranchID,
      //   financeBranchID: value?.financeBranchID,
      //   serviceBranchID: value?.serviceBranchID,
      //   salesRoleID: value?.salesRoleID,
      //   financeRoleID: value?.financeRoleID,
      //   serviceRoleID: value?.serviceRoleID,
      //   isRestrictedToSalesBranch: value?.isRestrictedToSalesBranch,
      //   restrictedToSalesCompanyID: value?.isRestrictedToSalesBranch ? null : value?.restrictedToSalesCompanyID || null,
      //   isRestrictedToFinanceBranch: value?.isRestrictedToFinanceBranch,
      //   restrictedToFinanceCompanyID: value?.isRestrictedToFinanceBranch ? null : value?.restrictedToFinanceCompanyID || null,
      //   isRestrictedToServiceBranch: value?.isRestrictedToServiceBranch,
      //   restrictedToServiceCompanyID: value?.isRestrictedToServiceBranch ? null : value?.restrictedToServiceCompanyID || null,
      //   isRestrictedByIPAddress: value?.isRestrictedByIPAddress,
      //   isActive: value?.isActive,
      //   title: value?.Title,
      //   firstName: value?.firstName,
      //   lastName: value?.lastName,
      //   middleName: value?.middleName,
      //   suffix: value?.suffix,
      //   nickName: value?.nickName,
      //   businessName: value?.businessName,
      //   dba: value?.dba,
      //   dateOfBirth: convertDateToString(value?.dateOfBirth),
      //   taxIDNumber: value?.taxIDNumber,
      //   email: value?.email,
      //   isContactUpdated: true,
      //   addressID: addressID?.toString() as string,
      //   address1: value?.Address1,
      //   address2: value?.Address2,
      //   city: value?.City,
      //   state: value?.State,
      //   postalCode: value?.PostalCode,
      //   county: value?.County,
      //   addressType: value?.AddressType,
      //   isPrimaryAddress: true,
      //   isAddressActive: true,
      //   isAddressUpdated: true,
      //   isPhoneUpdated: true,
      //   jsonPhoneData: [
      //     {
      //       ContactPhoneID: contactPhoneID as number,
      //       PhoneType: value?.PhoneType || 'Cell',
      //       PhoneNumber: removeMask(value.PhoneNumber) as string,
      //       PhoneExtension: value?.PhoneExtension || null,
      //       OrderOfPreference: 1,
      //       IsPreferred: true,
      //       IsPrimary: true,
      //       IsAllowCall: true,
      //       IsAllowSMS: true,
      //       IsActive: true,
      //     },
      //   ],
      // }
      editUser({
        userId: userId as string,
        uspUserAddEdit: uspUserAddEdit,
      })
    }
  }

  useEffect(() => {
    if (getUserIsSuccess) {
      const userDataSales = userData?.find((item) => item.CompanyType === 'Sales')
      const userDataFinance = userData?.find((item) => item.CompanyType === 'Finance')
      const userDataService = userData?.find((item) => item.CompanyType === 'Service')
      const contactId = userDataSales?.ContactID || userDataFinance?.ContactID || userDataService?.ContactID
      setIsInvitationAccepted(
        !!(userDataSales?.IsInvitationAccepted || userDataFinance?.IsInvitationAccepted || userDataService?.IsInvitationAccepted),
      )
      const transformUserData = {
        userID: userDataSales?.UserID || userDataFinance?.UserID || userDataService?.UserID,
        userCode: userDataSales?.UserCode || userDataFinance?.UserCode || userDataService?.UserCode,
        username: userDataSales?.Username || userDataFinance?.Username || userDataService?.Username,
        ContactID: userDataSales?.ContactID || userDataFinance?.ContactID || userDataService?.ContactID,
        isActive: userDataSales?.IsActive || userDataFinance?.IsActive || userDataService?.IsActive,
        isAllowedViewEmployeeAccounts:
          userDataSales?.IsAllowedViewEmployeeAccounts ||
          userDataFinance?.IsAllowedViewEmployeeAccounts ||
          userDataService?.IsAllowedViewEmployeeAccounts,
        isRestrictedByIPAddress: userDataSales?.IsRestrictedByIPAddress || userDataFinance?.IsRestrictedByIPAddress,
        isAllowedAccessSales: userDataSales?.IsAllowedAccess,
        isAllowedAccessFinance: userDataFinance?.IsAllowedAccess,
        isAllowedAccessService: userDataService?.IsAllowedAccess,
        salesBranchID: userDataSales?.BranchID,
        financeBranchID: userDataFinance?.BranchID,
        serviceBranchID: userDataService?.BranchID,
        salesRoleID: userDataSales?.RoleID,
        financeRoleID: userDataFinance?.RoleID,
        serviceRoleID: userDataService?.RoleID,
        isRestrictedToSalesBranch: userDataSales?.IsRestrictedToBranch,
        isRestrictedToFinanceBranch: userDataFinance?.IsRestrictedToBranch,
        isRestrictedToServiceBranch: userDataService?.IsRestrictedToBranch,
        restrictedToSalesCompanyID: userDataSales?.RestrictedToCompanyID,
        restrictedToFinanceCompanyID: userDataFinance?.RestrictedToCompanyID,
        restrictedToServiceCompanyID: userDataService?.RestrictedToCompanyID,
      }
      setContactID(contactId)
      setFormValues(transformUserData)
      getContact({ contactId: contactId?.toString() as string })
      reset()
    }
  }, [getUserIsSuccess, userData])

  useEffect(() => {
    if (getContactIsSuccess) {
      const contactAddressData = (contactData?.ContactAddressData &&
        contactData?.ContactAddressData?.length > 0 &&
        contactData?.ContactAddressData[0]) as AddressData

      setAddressId(contactAddressData?.AddressID)

      const contactPhoneData = (contactData?.ContactPhoneData &&
        contactData?.ContactPhoneData?.length > 0 &&
        contactData?.ContactPhoneData[0]) as UspContactPhoneData
      setContactPhoneID(contactPhoneData?.ContactPhoneID)
      setInitialValues({
        accountType: contactData?.BusinessName ? 'business' : 'individual',
        firstName: contactData?.FirstName?.trim(),
        middleName: contactData?.MiddleName?.trim(),
        lastName: contactData?.LastName?.trim(),
        nickName: contactData?.NickName?.trim(),
        suffix: contactData?.Suffix?.trim(),
        businessName: contactData?.BusinessName?.trim(),
        email: contactData?.Email?.trim(),
        dateOfBirth: convertStringToDate(contactData?.DateOfBirth),
        taxIDNumber: removeMask(contactData?.TaxIDNumber),
        Address1: contactAddressData?.Address1?.trim(),
        Address2: contactAddressData?.Address2?.trim(),
        City: contactAddressData?.City?.trim(),
        State: contactAddressData?.State,
        PostalCode: contactAddressData?.PostalCode?.trim(),
        County: contactAddressData?.County?.trim(),
        PhoneType: contactPhoneData?.PhoneType,
        PhoneNumber: contactPhoneData?.PhoneNumber,
        PhoneExtension: contactPhoneData?.PhoneExtension,
        isAllowedViewEmployeeAccounts: !!formValues?.isAllowedViewEmployeeAccounts,
        isAllowedAccessSales: formValues?.isAllowedAccessSales,
        isAllowedAccessFinance: formValues?.isAllowedAccessFinance,
        isAllowedAccessService: formValues?.isAllowedAccessService,
        salesBranchID: formValues?.salesBranchID,
        financeBranchID: formValues?.financeBranchID,
        serviceBranchID: formValues?.serviceBranchID,
        salesRoleID: formValues?.salesRoleID,
        financeRoleID: formValues?.financeRoleID,
        serviceRoleID: formValues?.serviceRoleID,
        isRestrictedToSalesBranch: formValues?.isRestrictedToSalesBranch,
        restrictedToSalesCompanyID: formValues?.restrictedToSalesCompanyID,
        isRestrictedToFinanceBranch: formValues?.isRestrictedToFinanceBranch,
        restrictedToFinanceCompanyID: formValues?.restrictedToFinanceCompanyID,
        isRestrictedToServiceBranch: formValues?.isRestrictedToServiceBranch,
        restrictedToServiceCompanyID: formValues?.restrictedToServiceCompanyID,
        username: formValues?.username?.trim() || contactData?.Email?.trim(),
        userCode: formValues?.userCode?.trim(),
        isRestrictedByIPAddress: formValues?.isRestrictedByIPAddress,
        isActive: formValues?.isActive,
      })
    }
  }, [getContactIsSuccess, formValues])

  useEffect(() => {
    if (editUserIsSuccess) {
      dispatch(enqueueNotification({ message: 'Successful user edit' }))
      navigate(appRoute?.UserList)
    }
  }, [editUserIsSuccess])

  useEffect(() => {
    if (addUserIsSuccess) {
      dispatch(enqueueNotification({ message: 'Successful user creation' }))
      navigate(appRoute?.UserList)
    }
  }, [addUserIsSuccess])

  const isLoading = () => {
    if (userId !== 'add') {
      return userPermissionsDropdownIsLoading || addUserIsLoading || addUserIsLoading
    } else {
      return (
        getUserIsLoading ||
        getContactIsLoading ||
        userPermissionsDropdownIsLoading ||
        userPermissionsDropdownIsLoading ||
        editUserIsLoading ||
        editUserIsLoading
      )
    }
  }

  return (
    <Container>
      <CustomBreadcrumbs
        heading='User'
        links={[
          {
            name: 'List',
            href: appRoute.UserList,
          },
          { name: 'User' },
        ]}
        // action={
        //   <Button
        //     onClick={() => navigate(appRoute?.VendorAddEdit.replace(':vendorId', 'add'))}
        //     variant='contained'
        //     startIcon={<Iconify icon='mingcute:add-line' />}
        //   >
        //     Add new
        //   </Button>
        // }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateForm(userValidation)}>
        {({ handleSubmit, valid }: any) => (
          <form onSubmit={handleSubmit}>
            <Card sx={{ position: 'relative', overflow: 'visible', display: 'flex', flexDirection: 'column', height: '100%' }}>
              {/* <Header isInvitationAccepted={isInvitationAccepted} /> */}
              <Grid container spacing={3} p={2}>
                <Grid item xs={12}>
                  <NameForm />
                </Grid>
                <Grid item xs={12}>
                  <AddressForm />
                </Grid>
                <Grid item xs={12}>
                  <ContactForm />
                </Grid>
                <Grid item xs={12}>
                  <IdentityForm />
                </Grid>
                <Grid item xs={12}>
                  <UserSettingsForm />
                </Grid>
                {activeCompanyTypesData && activeCompanyTypesData.find((item) => item?.CompanyType?.toLowerCase() === 'sales') && (
                  <Grid item xs={12}>
                    <SalesDepartmentForm />
                  </Grid>
                )}
                {activeCompanyTypesData && activeCompanyTypesData.find((item) => item?.CompanyType?.toLowerCase() === 'finance') && (
                  <Grid item xs={12}>
                    <FinanceDepartmentForm />
                  </Grid>
                )}
                {activeCompanyTypesData && activeCompanyTypesData.find((item) => item?.CompanyType?.toLowerCase() === 'service') && (
                  <Grid item xs={12}>
                    <ServiceDepartmentForm />
                  </Grid>
                )}
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'sticky',
                  bottom: 16,
                  right: 20,
                  gap: 1,
                  p: 3,
                  width: 'fit-content',
                  alignSelf: 'flex-end',
                }}
              >
                <Button variant='contained' color='error' onClick={() => navigate(appRoute.UserList)}>
                  Close
                </Button>
                <Button type='submit' className={classes.saveBtn} variant='contained'>
                  Save & Close
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading()} />
    </Container>
  )
}
