import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import Header from './components/Header/Header'
import { Box, Button, Card, Container, Grid, Paper, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { useEditStateMutation, useSearchStateMutation } from '../../../services'
import TaxesForm from './components/TaxesForm/TaxesForm'
import FeesForm from './components/FeesForm/FeesForm'
import DealsForm from './components/DealsForm/DealsForm'
import InterestForm from './components/InterestForm/InterestForm'
import { CustomCircular } from '../../../ui'
import { withPermissionsDropdown } from '../../../hoc'
import { transformToFormValues, transformToRequestValues } from './transform'
import ConfirmDialog from '../../../components/ConfirmDialog'
import ReactRouterPrompt from 'react-router-prompt'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('form', ['root', 'firstRow', 'buttonWrap', 'saveBtn', 'pageBody'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.firstRow}`]: {
    minHeight: '300px',
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    position: 'sticky',
    right: '0px',
    bottom: '0px',
    justifyContent: 'flex-between',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

const SettingsStateSettings = () => {
  const dispatch = useDispatch()
  const [formChanged, setFormChanged] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [searchState, { isLoading: searchStateIsLoading, data }] = useSearchStateMutation()
  const [editState, { isLoading: editStateIsLoading, isSuccess: editStateIsSuccess }] = useEditStateMutation()

  const handleSubmit = (values: any) => {
    editState({
      stateId: values?.State,
      uspStateSettingEdit: transformToRequestValues(values) as any,
    })
  }

  useEffect(() => {
    if (data) {
      setInitialValues(transformToFormValues(data))
    }
  }, [data])

  useEffect(() => {
    if (editStateIsSuccess) {
      setFormChanged(false)
      dispatch(enqueueNotification({ message: 'Success' }))
    }
  }, [editStateIsSuccess])

  const isLoading = useMemo(() => searchStateIsLoading || editStateIsLoading, [searchStateIsLoading, editStateIsLoading])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='State Settings'
        links={[]}
        // action={
        //   <>
        //     <Button onClick={handleOpenModal} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
        //       Add new
        //     </Button>
        //   </>
        // }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
            <Card sx={{ position: 'relative', overflow: 'visible', display: 'flex', flexDirection: 'column', height: '100%' }}>
              <ReactRouterPrompt when={formChanged}>
                {({ isActive, onConfirm, onCancel }) => (
                  <ConfirmDialog
                    open={!!isActive}
                    onOk={onConfirm}
                    handleClose={onCancel}
                    notification={'Warning: Your changes haven’t been saved.'}
                  />
                )}
              </ReactRouterPrompt>
              <Header searchState={searchState} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TaxesForm onDropdownFormChange={() => setFormChanged(true)} />
                </Grid>
                <Grid item xs={12}>
                  <FeesForm onDropdownFormChange={() => setFormChanged(true)} />
                </Grid>
                <Grid item xs={12}>
                  <DealsForm onDropdownFormChange={() => setFormChanged(true)} />
                </Grid>
                <Grid item xs={12}>
                  <InterestForm onDropdownFormChange={() => setFormChanged(true)} />
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'sticky',
                  bottom: 16,
                  right: 20,
                  gap: 1,
                  p: 3,
                  width: 'fit-content',
                  alignSelf: 'flex-end',
                }}
              >
                <Button disabled={!formChanged} type='submit' className={classes.saveBtn} variant='contained'>
                  Save
                </Button>
              </Box>
              <CustomCircular open={isLoading} />
            </Card>
          </form>
        )}
      </Form>
    </Container>
  )
}

export default withPermissionsDropdown(SettingsStateSettings)
