import React, { useEffect, useState } from 'react'
import { useCollectionWorklistMutation, useGetTimeMutation } from '../../../services'
import { fetchCurrentDateTimeFromServer, getCurrentDate } from '../../../utils/general'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import Header from './components/Header'
import CollectionsMyWorklistTableblock from './components/CollectionsMyWorklistTableblock'
import { SearchWorklistApiResponse } from '../../../models'
import { getWorklistName } from '../../../redux/slices/collectionsSlice'
import { Box, Button, Card, Container, Grid, Popover } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { convertDateToString } from '../../../utils/dateUtils'

const CollectionsMyWorklist = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const worklistName = useSelector(getWorklistName)
  const [getCollections, { data, isLoading, isSuccess }] = useCollectionWorklistMutation()
  const [selected, setSelected] = useState<number[]>([])
  const [anchorElAdd, setAnchorElAdd] = React.useState<HTMLButtonElement | null>(null)
  const [getTime] = useGetTimeMutation()

  const open = Boolean(anchorElAdd)
  const id = open ? 'add-menu-popover' : undefined

  const handleOpenAddMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAdd(event.currentTarget)
  }

  const handleCloseAddMenu = () => {
    setAnchorElAdd(null)
  }
  useEffect(() => {
    fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
      getCollections({
        uspCollectionWorklistCommon: {
          effectiveDate: convertDateToString(currentDate as string) ?? '',
        },
      })
    })
  }, [])

  useEffect(() => {
    if (isSuccess && data?.length === 0) {
      dispatch(enqueueNotification({ message: 'You are not logged in to an active worklist.', options: { variant: 'warning' } }))
      navigate(-1)
    }
  }, [isSuccess, data])

  return (
    <Container>
      {/* <Header worklistName={worklistName} selected={selected} collectionsData={data as SearchWorklistApiResponse} /> */}
      <CustomBreadcrumbs
        heading={`Worklist - ${data?.[0]?.WorklistName || worklistName}`}
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <>
            <Button
              disabled={selected.length < 1}
              aria-describedby={id}
              variant='contained'
              onClick={handleOpenAddMenu}
              endIcon={<KeyboardArrowDownIcon />}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', minWidth: '140px', margin: 0, padding: '10px' }}
            >
              Bulk Actions
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorElAdd}
              onClose={handleCloseAddMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box style={{ display: 'flex', flexDirection: 'column', width: '130px' }}>
                <Button style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }} disabled>
                  Send Text
                </Button>
                <Button style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }} disabled>
                  Send Email
                </Button>
              </Box>
            </Popover>
          </>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />

      <Card
        sx={{
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
          py: 2,
        }}
      >
        <CollectionsMyWorklistTableblock
          setSelected={setSelected}
          selected={selected}
          loading={isLoading}
          collectionsData={data as SearchWorklistApiResponse}
        />
      </Card>
    </Container>
  )
}

export default CollectionsMyWorklist
