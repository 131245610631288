import { useForm, Controller } from 'react-hook-form'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { transformToRequestValues } from './transform'
import {
  Box,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormGroup,
  FormHelperText,
  IconButton,
  InputBase,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
  TextField as MuiTextField,
  Chip,
  Button,
} from '@mui/material'
import { TextField } from '../../../../ui'
import Iconify from '../../../../components/iconify'
import { useCallback, useEffect, useRef, useState } from 'react'
import NotesItem from './notes-item'
import EditIcon from '@mui/icons-material/Edit'
import { AmountField, CheckboxField, DateField } from '../../../../ui'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import {
  selectDropdownByName,
  selectDropdownByNameActiveOnly,
  useAddNotesMutation,
  useGetTimeMutation,
  useLazyGetAccountNotesHistoryListQuery,
} from '../../../../services'
import { Field, Form } from 'react-final-form'
import { accountsAddNoteValidation } from '../../../../validation/accountsAddNote'
import validateFormValues from '../../../../utils/validateForm'
import DateTimeField from '../../../../ui/DateTimeField'
import { getUserAccessRights } from '../../../../redux/slices/authSlice'
import { useParams } from 'react-router-dom'
import { useFetchTemplateLeadMutation, useSearchTemplateMutation } from '../../../../services/Templates/TemplateApi'
import { fetchCurrentDateTimeFromServer, getCurrentDate } from '../../../../utils/general'
import { useGetTextSuggestionsMutation } from '../../../../services/AI/AIApi'
import { convertDateToString } from '../../../../utils/dateUtils'
import RHFEditor from '../../../../components/hook-form/rhf-editor'
import Editor from '../../../../components/editor'
import { border } from '@mui/system'

// ----------------------------------------------------------------------

const NOTES_DATA = [
  {
    id: 1,
    name: 'Jayvion Simon',
    address: '1112 Davis St Redwood City, CA 94061',
    phoneNumber: '(365) 374-4961',
    date: '02/01/2024 10:00 PM',
    user: 'Alex Johnson',
  },
]
const ADDITIONAL_OPTIONS = [
  { label: 'Create Follow-Up', value: 'followUp', name: 'isCreateFollowUp' },
  { label: 'Create Promise-To-Pay', value: 'promisePay', name: 'isCreatePTP' },
]

// ----------------------------------------------------------------------

export default function LeadNotes({ data }: any) {
  const { dealID, leadID } = useParams()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const noteRef = useRef(null)
  const [getTextSuggestion, { data: getTextSuggestionData, isSuccess: getTextSuggestionisSuccess, isLoading: getTextSuggestionIsLoading }] =
    useGetTextSuggestionsMutation()
  const [tabValue, setTabValue] = useState(1)
  const [addNotes, { isSuccess, isLoading: isLoadingAddNotes }] = useAddNotesMutation()
  const assignedToDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'UserAll'))
  const callOutcomeDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CallOutcome'))
  const followUpTypeDropdown = useSelector((state) => selectDropdownByName(state, 'FollowUpType'))
  const [refresh, setRefresh] = useState(false)
  const [GetAccountNotesHistory, { data: notes, isLoading: GetAccountNotesHistoryIsLoading }] = useLazyGetAccountNotesHistoryListQuery()
  const { UserID } = useSelector(getUserAccessRights)
  const twilioDropdown = useSelector((state) => selectDropdownByName(state, 'Twilio'))
  const [searchTemplate, { data: templateData, isSuccess: searchTemplateSuccess, isLoading }] = useSearchTemplateMutation()
  const [fetchTemplate, { data: fetchTemplateData, isSuccess: fetchTemplateSuccess }] = useFetchTemplateLeadMutation()
  const [getTime] = useGetTimeMutation()
  const [items, setItems] = useState<any>([])
  const [value, setValue] = useState<any>('')
  const [itemsCC, setItemsCC] = useState<any>([])
  const [valueCC, setValueCC] = useState<any>('')
  const [emailError, setError] = useState<any>(null)
  const [emailErrorCC, setErrorCC] = useState<any>(null)
  const [addCC, setAddCC] = useState<any>(null)
  var formVar: any
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }, [])
  const handleSubmit = (values: any, form: any) => {
    addNotes({
      addNotes: transformToRequestValues(
        values,
        dealID,
        tabValue,
        leadID,
        twilioDropdown?.ReturnData.find((el) => el.BranchID === data.BranchID),
        items,
        itemsCC,
      ),
    })
  }

  useEffect(() => {
    if (isSuccess) {
      setItems([])
      setValue('')
      setItemsCC([])
      setValueCC('')
      formVar.restart()
    }
  }, [isSuccess, isLoadingAddNotes])

  const handleKeyDown = (evt: any) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault()

      let val: any = value.trim()

      if (val && isValid(val)) {
        setItems([...items, val])
        setValue('')
      }
    }
  }

  const handleOnBlur = (evt: any) => {
    evt.preventDefault()

    let val: any = value.trim()

    if (val && isValid(val)) {
      setItems([...items, val])
      setValue('')
    }
  }

  const handleKeyDownCC = (evt: any) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault()

      let val: any = valueCC.trim()

      if (val && isValidCC(val)) {
        setItemsCC([...itemsCC, val])
        setValueCC('')
      }
    }
  }

  const handleOnBlurCC = (evt: any) => {
    evt.preventDefault()

    let val: any = valueCC.trim()

    if (val && isValidCC(val)) {
      setItemsCC([...itemsCC, val])
      setValueCC('')
    }
  }

  const handleChange = (e: any) => {
    setValue(e.target.value)
    setError(null)
  }

  const handleDelete = (item: any) => {
    setItems(items.filter((i: any) => i !== item))
  }

  const handleChangeCC = (e: any) => {
    setValueCC(e.target.value)
    setErrorCC(null)
  }

  const handleDeleteCC = (item: any) => {
    setItemsCC(itemsCC.filter((i: any) => i !== item))
  }

  function isValid(email: any) {
    let error = null

    if (isInList(email)) {
      error = `${email} has already been added.`
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`
    }

    if (error) {
      setError(error)

      return false
    }

    return true
  }

  function isInList(email: any) {
    return items.includes(email)
  }

  function isEmail(email: any) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email)
  }

  function isValidCC(email: any) {
    let error = null

    if (isInListCC(email)) {
      error = `${email} has already been added.`
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`
    }

    if (error) {
      setErrorCC(error)

      return false
    }

    return true
  }

  function isInListCC(email: any) {
    return items.includes(email)
  }

  const handlePaste = (evt: any) => {
    evt.preventDefault()

    var paste = evt.clipboardData.getData('text')
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g)

    if (emails) {
      var toBeAdded = emails.filter((email: any) => !isInList(email))

      setItems([...items, ...toBeAdded])
    }
  }
  const handlePasteCC = (evt: any) => {
    evt.preventDefault()

    var paste = evt.clipboardData.getData('text')
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g)

    if (emails) {
      var toBeAdded = emails.filter((email: any) => !isInList(email))

      setItemsCC([...itemsCC, ...toBeAdded])
    }
  }

  const TABS = [
    {
      value: 1,
      label: 'Note',
      icon: <TextSnippetIcon />,
    },
    {
      value: 2,
      label: 'Call',
      icon: <Iconify icon='fluent:call-24-filled' width={24} />,
    },
    {
      value: 3,
      label: 'Email',
      disabled: false,
      icon: <Iconify icon='ic:baseline-email' width={24} />,
    },
    {
      value: 4,
      label: 'Text',
      disabled: twilioDropdown && twilioDropdown.ReturnData.find((el) => el.BranchID === data.BranchID)?.IsActive ? false : true,
      icon: <Iconify icon='heroicons:chat-bubble-oval-left-ellipsis-solid' width={24} />,
    },
  ]

  useEffect(() => {
    GetAccountNotesHistory({
      accountNotesHistory: {
        isIncludeDeletedNotes: false,
        isImportantMessagesOnly: false,
        creditAppID: parseInt(leadID as string),
      },
    })
    searchTemplate({
      data: {
        dataSource: 'Lead',
        isIncludeInactive: false,
        searchString: null,
      },
    })
  }, [refresh])

  useEffect(() => {
    if (fetchTemplateSuccess) {
      formVar.change('Note', fetchTemplateData.SmsMessage)
    }
  }, [fetchTemplateData])
  return (
    <Form
      onSubmit={handleSubmit}
      validate={validateFormValues(accountsAddNoteValidation(tabValue))}
      initialValues={{
        emailText: '',
        CallCallType: 'Outbound',
        assignedToUserID: UserID,
        PhoneNumber: data?.CellPhoneData?.[0]?.PhoneNumber,
      }}
    >
      {({ values, form, handleSubmit }: any) => {
        formVar = form
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Box sx={{ mb: 3 }}>
                    <Box
                      sx={{
                        p: 1,
                        mb: 3,
                        borderRadius: 1,
                        // width: 'fit-content',
                        border: (theme) => `solid 1px ${alpha(theme.palette.grey[400], 0.2)}`,
                      }}
                    >
                      {isMobile ? (
                        <Tabs
                          // sx={{
                          //   width: 'fit-content',
                          //   '& .MuiTabs-flexContainer': {
                          //     width: 'fit-content',
                          //   },
                          // }}
                          value={tabValue}
                          onChange={(e, newValue) => {
                            handleChangeTab(e, newValue)
                            form.restart()
                          }}
                          scrollButtons={false}
                          variant={'scrollable'}
                        >
                          {TABS.map((tab) => (
                            <Tab key={tab.value} disabled={tab.disabled} label={tab.label} icon={tab.icon} value={tab.value} />
                          ))}
                        </Tabs>
                      ) : (
                        <Tabs
                          // sx={{
                          //   width: 'fit-content',
                          //   '& .MuiTabs-flexContainer': {
                          //     width: 'fit-content',
                          //   },
                          // }}
                          value={tabValue}
                          onChange={(e, newValue) => {
                            handleChangeTab(e, newValue)
                            form.restart()
                          }}
                          variant={'fullWidth'}
                        >
                          {TABS.map((tab) => (
                            <Tab key={tab.value} disabled={tab.disabled} label={tab.label} icon={tab.icon} value={tab.value} />
                          ))}
                        </Tabs>
                      )}
                    </Box>
                  </Box>
                  <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    {/* {tabValue == 1 && (
                    <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
                      <TextField
                        multiline
                        fullWidth
                        label='Note'
                        name='Note'
                        rows={4}
                        placeholder='Enter note here...'
                        sx={{
                          mb: 3,
                        }}
                      />
                      <CheckboxField name='isCreateFollowUp' label='Create Follow-Up' />
                      {values.isCreateFollowUp && (
                        <Grid container xs={12} columnSpacing={2}>
                          <Grid xs={4}>
                            {followUpTypeDropdown?.ReturnData && (
                              <DropdownFieldSearch
                                name='followUpTypeID'
                                label='Follow-up Type'
                                options={followUpTypeDropdown?.ReturnData}
                                optionKey='ID'
                                optionValue='FollowUpType'
                                fullWidth
                              />
                            )}
                          </Grid>
                          {assignedToDropdown && (
                            <Grid xs={4}>
                              <DropdownFieldSearch
                                name='assignedToUserID'
                                label='Assign To'
                                options={assignedToDropdown}
                                optionKey='UserID'
                                optionValue='Name'
                                fullWidth
                              />
                            </Grid>
                          )}

                          <Grid xs={4}>
                            <DateTimeField name='followUpDateTime' label='Follow-Up Date Time' disablePast />
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  )}

                  {tabValue == 2 && (
                    <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
                      <Grid container spacing={2}>
                        <Grid xs={3} sx={{ display: 'flex', alignItems: 'flex-start', gap: '10px' }}>
                          <DropdownFieldSearch
                            name='CallCallType'
                            label='Call Type'
                            options={[
                              { key: 'Outbound', value: 'Outbound' },
                              { key: 'Inbound', value: 'Inbound' },
                            ]}
                            optionKey='key'
                            optionValue='value'
                            fullWidth
                          />
                        </Grid>
                        <Grid xs={3} sx={{ display: 'flex', alignItems: 'flex-start', gap: '10px' }}>
                          {values.CallCallType === 'Outbound' && callOutcomeDropdown && (
                            <DropdownFieldSearch
                              name='CallOutcome'
                              label='Outcome'
                              options={callOutcomeDropdown}
                              optionKey='ID'
                              optionValue='Outcome'
                              fullWidth
                            />
                          )}
                        </Grid>
                        <Grid xs={12}>
                          <TextField
                            multiline
                            fullWidth
                            name='CallNote'
                            label='Note'
                            variant='outlined'
                            rows={4}
                            placeholder='Enter note here...'
                            sx={{
                              // p: 2,
                              borderRadius: 1,
                              // border: (theme: any) => `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
                            }}
                          />
                        </Grid>

                        <Grid xs={12}>
                          <CheckboxField name='isCreateFollowUp' label='Create Follow-Up' />
                        </Grid>

                        {values.isCreateFollowUp && (
                          <Grid container xs={12} columnSpacing={2}>
                            <Grid xs={3}>
                              {followUpTypeDropdown?.ReturnData && (
                                <DropdownFieldSearch
                                  name='followUpTypeID'
                                  label='Follow-up Type'
                                  options={followUpTypeDropdown?.ReturnData}
                                  optionKey='ID'
                                  optionValue='FollowUpType'
                                  fullWidth
                                />
                              )}
                            </Grid>
                            {assignedToDropdown && (
                              <Grid xs={3}>
                                <DropdownFieldSearch
                                  name='assignedToUserID'
                                  label='Assign To'
                                  options={assignedToDropdown}
                                  optionKey='UserID'
                                  optionValue='Name'
                                  fullWidth
                                />
                              </Grid>
                            )}

                            <Grid xs={6}>
                              <DateTimeField name='followUpDateTime' label='Follow-Up Date Time' disablePast />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )} */}
                    <Box overflow={'visible'} display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
                      <Grid container spacing={2}>
                        {tabValue == 2 && (
                          <>
                            <Grid xs={12} sm={3}>
                              <DropdownFieldSearch
                                name='CallCallType'
                                label='Call Type'
                                options={[
                                  { key: 'Outbound', value: 'Outbound' },
                                  { key: 'Inbound', value: 'Inbound' },
                                ]}
                                optionKey='key'
                                optionValue='value'
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={12} sm={3}>
                              {values.CallCallType === 'Outbound' && callOutcomeDropdown && (
                                <DropdownFieldSearch
                                  name='CallOutcome'
                                  label='Outcome'
                                  options={callOutcomeDropdown}
                                  optionKey='ID'
                                  optionValue='Outcome'
                                  fullWidth
                                />
                              )}
                            </Grid>
                          </>
                        )}

                        {tabValue == 4 && data.CellPhoneData?.length > 0 && (
                          <>
                            <Grid xs={12} sm={6}>
                              <DropdownFieldSearch
                                name='PhoneNumber'
                                label='To'
                                options={data.CellPhoneData.map((el: any) => {
                                  return {
                                    Name: `${el.Name} <${el.FormattedPhoneNumber}>`,
                                    PhoneNumber: el.PhoneNumber,
                                  }
                                })}
                                optionKey='PhoneNumber'
                                optionValue='Name'
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={12} sm={6}>
                              {values.PhoneNumber && (
                                <DropdownFieldSearch
                                  name='template'
                                  label='Template'
                                  options={templateData?.map((el: any) => {
                                    return {
                                      Name: `${el.TemplateName} <${el.TemplateCategory}>`,
                                      ID: el.ID,
                                    }
                                  })}
                                  onChange={(value) => {
                                    if (value)
                                      fetchTemplate({
                                        sms_template_id: value,
                                        data: {
                                          contactID: data.CellPhoneData.find((el: { PhoneNumber: any }) => el.PhoneNumber == values.PhoneNumber)
                                            .ContactID,
                                          creditAppID: parseInt(leadID as string),
                                        },
                                      })
                                  }}
                                  optionKey='ID'
                                  optionValue='Name'
                                  fullWidth
                                />
                              )}
                            </Grid>
                          </>
                        )}
                        {tabValue == 4 ? (
                          <Grid xs={12}>
                            <Box
                              sx={{
                                border: 1,
                                borderColor: '#e8ecee',
                                width: '100%',
                                borderRadius: '8px',
                                '&:hover': {
                                  cursor: 'text',
                                },
                              }}
                              pb={2}
                              onClick={() => {
                                //@ts-ignore
                                noteRef?.current?.focus()
                              }}
                            >
                              <Grid container xs={12} spacing={1} justifyContent={'flex-end'}>
                                <Grid xs={12}>
                                  <TextField
                                    sx={{ '& fieldset': { border: 'none' } }}
                                    required
                                    forwardRef={noteRef}
                                    InputLabelProps={{
                                      sx: {
                                        background: theme.palette.background.paper,
                                        '&.Mui-focused': { background: theme.palette.background.paper },
                                      },
                                    }}
                                    name='Note'
                                    label='Note'
                                    multiline={true}
                                    minRows={1}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid xs={4} px={2}>
                                  {values.PhoneNumber && (
                                    <Grid container alignItems={'center'} justifyContent={'end'} xs={2} sm={1}>
                                      <Tooltip title='AI Text Suggestion'>
                                        <IconButton
                                          type='button'
                                          onClick={async () => {
                                            if (data.CellPhoneData?.length > 0 && leadID && !getTextSuggestionIsLoading) {
                                              const currDate = await fetchCurrentDateTimeFromServer(getTime)
                                              const res = await getTextSuggestion({
                                                data: {
                                                  contactID: data.CellPhoneData.find(
                                                    (el: { PhoneNumber: any }) => el.PhoneNumber == values.PhoneNumber,
                                                  )?.ContactID,
                                                  dataSource: 'Lead',
                                                  sourceID: Number(leadID),
                                                  effectiveDate: convertDateToString(currDate as string) ?? '',
                                                  phoneNumber: data.CellPhoneData.find(
                                                    (el: { PhoneNumber: any }) => el.PhoneNumber == values.PhoneNumber,
                                                  )?.PhoneNumber,
                                                },
                                              })
                                              //@ts-ignore
                                              if (res?.data) form.change('Note', res?.data?.SuggestedText)
                                            }
                                          }}
                                        >
                                          {getTextSuggestionIsLoading ? (
                                            <CircularProgress size={24} />
                                          ) : (
                                            <Iconify icon='mingcute:ai-fill' width={24} />
                                          )}
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        ) : (
                          tabValue !== 3 && (
                            <Grid xs={12}>
                              <TextField
                                multiline
                                fullWidth
                                label={tabValue == 4 ? 'Message' : 'Note'}
                                name={tabValue == 2 ? 'CallNote' : 'Note'}
                                variant='outlined'
                                rows={4}
                                placeholder={tabValue == 4 ? 'Enter message here...' : 'Enter note here...'}
                                sx={{
                                  borderRadius: 1,
                                  // border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
                                }}
                              />
                            </Grid>
                          )
                        )}

                        {tabValue == 3 && (
                          <Grid xs={12}>
                            <Field name='emailText'>
                              {({ input, meta: { error, touched } }) => (
                                <Editor
                                  id={'content'}
                                  value={input.value}
                                  onChange={input.onChange}
                                  error={!!error}
                                  helperText={
                                    !!error && (
                                      <FormHelperText error={!!error} sx={{ px: 2 }}>
                                        {error}
                                      </FormHelperText>
                                    )
                                  }
                                >
                                  <>
                                    <Stack direction={'row'} flexWrap={'nowrap'} py={1} alignItems={'end'} spacing={1}>
                                      <MuiTextField
                                        error={!!emailError}
                                        helperText={emailError}
                                        value={value}
                                        InputProps={{
                                          startAdornment: (
                                            <Stack direction={'row'} py={1} spacing={1} pr={1} pl={2} alignItems={'center'}>
                                              <Typography variant='body1'>To</Typography>
                                              {items?.map((el: any) => (
                                                <Chip
                                                  key={el}
                                                  label={el}
                                                  variant='outlined'
                                                  // onClick={handleClick}
                                                  onDelete={() => handleDelete(el)}
                                                />
                                              ))}
                                            </Stack>
                                          ),
                                        }}
                                        placeholder='Type an email'
                                        onKeyDown={handleKeyDown}
                                        onChange={handleChange}
                                        onBlur={handleOnBlur}
                                        onPaste={handlePaste}
                                        fullWidth={true}
                                        variant='standard'
                                        size='small'
                                      />
                                    </Stack>
                                    <TextField
                                      name='Subject'
                                      sx={{
                                        border: 'unset',
                                        outline: 'unset',
                                        '& fieldset': { border: 'none' },
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <Stack direction={'row'} py={1} spacing={1} pr={1} pl={2} alignItems={'center'}>
                                            <Typography variant='body1'>Subject</Typography>
                                          </Stack>
                                        ),
                                        endAdornment: (
                                          <Button size='small' onClick={() => setAddCC(!addCC)} variant={addCC ? 'contained' : 'text'}>
                                            Add Cc
                                            {/* <Typography variant='body1'></Typography> */}
                                          </Button>
                                        ),
                                      }}
                                      fullWidth={true}
                                      variant='standard'
                                      size='small'
                                    />
                                    {addCC && (
                                      <Stack direction={'row'} flexWrap={'nowrap'} py={1} alignItems={'end'} spacing={1}>
                                        <MuiTextField
                                          error={!!emailErrorCC}
                                          helperText={emailErrorCC}
                                          value={valueCC}
                                          InputProps={{
                                            startAdornment: (
                                              <Stack direction={'row'} py={1} spacing={1} pr={1} pl={2} alignItems={'center'}>
                                                <Typography variant='body1'>CC</Typography>
                                                {itemsCC?.map((el: any) => (
                                                  <Chip
                                                    key={el}
                                                    label={el}
                                                    variant='outlined'
                                                    // onClick={handleClick}
                                                    onDelete={() => handleDeleteCC(el)}
                                                  />
                                                ))}
                                              </Stack>
                                            ),
                                          }}
                                          placeholder='Type an email'
                                          onKeyDown={handleKeyDownCC}
                                          onChange={handleChangeCC}
                                          onBlur={handleOnBlurCC}
                                          onPaste={handlePasteCC}
                                          fullWidth={true}
                                          variant='standard'
                                          size='small'
                                        />
                                      </Stack>
                                    )}
                                  </>
                                </Editor>
                              )}
                            </Field>
                          </Grid>
                        )}
                        <Grid xs={12}>
                          <CheckboxField name='isCreateFollowUp' label='Create Follow-Up' />
                        </Grid>

                        {values.isCreateFollowUp && (
                          <Grid container xs={12} spacing={2}>
                            <Grid xs={12} sm={3}>
                              {followUpTypeDropdown?.ReturnData && (
                                <DropdownFieldSearch
                                  name='followUpTypeID'
                                  label='Follow-up Type'
                                  options={followUpTypeDropdown?.ReturnData}
                                  optionKey='ID'
                                  optionValue='FollowUpType'
                                  fullWidth
                                />
                              )}
                            </Grid>
                            {assignedToDropdown && (
                              <Grid xs={12} sm={3}>
                                <DropdownFieldSearch
                                  name='assignedToUserID'
                                  label='Assign To'
                                  options={assignedToDropdown}
                                  optionKey='UserID'
                                  optionValue='Name'
                                  fullWidth
                                />
                              </Grid>
                            )}

                            <Grid xs={12} sm={6}>
                              <DateTimeField sx={{ width: '100%' }} name='followUpDateTime' label='Follow-Up Date Time' fullWidth disablePast />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                  <Stack direction='row' alignItems='center' justifyContent={'flex-end'} sx={{ pt: 1 }}>
                    <LoadingButton type='submit' variant='contained' size='medium'>
                      Post
                    </LoadingButton>
                  </Stack>
                </Card>
              </Grid>
              {/* <Grid xs={12} md={4}>
              <Card sx={{ height: '100%' }}>
                <CardHeader title='Standing' />
                <Box sx={{ p: 3 }} display={'flex'} flexDirection={'column'}>
                  <Typography sx={{ py: 1 }} variant='body2'>
                    Branch: Porsche of Lexington
                  </Typography>
                  <Typography sx={{ py: 1 }} variant='body2'>
                    Lead Source: CarGurus
                  </Typography>
                </Box>
              </Card>
            </Grid> */}
              <Grid xs={12} md={8}>
                <Card>
                  <CardHeader title='Notes' />
                  <Stack spacing={2.5} sx={{ p: 3 }}>
                    {notes?.map((note: any) => (
                      <NotesItem
                        setRefresh={setRefresh}
                        key={note.NoteID}
                        NoteID={note.NoteID}
                        NoteType={note.NoteType}
                        IsPTP={note.IsPTP}
                        IsFollowUpCompleted={note.IsFollowUpCompleted}
                        IsPinned={note.IsPinned}
                        Subject={note.Subject}
                        phoneNumber={note.PhoneNumber}
                        IsInbound={note.IsInbound}
                        date={note.SystemDate}
                        SentByEmail={note.SentByEmail}
                        Outcome={note.Outcome}
                        SentToEmails={note.SentToEmails}
                        IsFollowUp={note.IsFollowUp}
                        PtpDueDate={note.PtpDueDate}
                        PtpAmount={note.PtpAmount}
                        FollowUpAssignedTo={note.FollowUpAssignedTo}
                        FollowUpDueDateTime={note.FollowUpDueDateTime}
                        user={note.UserCode}
                        note={note.Note}
                        name={note.NoteType}
                        FollowUpID={note.FollowUpID}
                        variant='outlined'
                        sx={{
                          p: 2.5,
                          borderRadius: 1,
                        }}
                      />
                    ))}
                  </Stack>
                </Card>
              </Grid>
              {/* <Grid xs={12} md={4}>
              <Card sx={{ height: '100%' }}>
                <CardHeader
                  title='Settings'
                  action={
                    <IconButton>
                     <Iconify icon='solar:pen-bold' />
                    </IconButton>
                  }
                />
                <Box sx={{ p: 3 }} display={'flex'} flexDirection={'column'}>
                  <Typography sx={{ py: 1 }} variant='body2'>
                    Branch: Porsche of Lexington
                  </Typography>
                  <Typography sx={{ py: 1 }} variant='body2'>
                    Lead Source: CarGurus
                  </Typography>
                </Box>
              </Card>
            </Grid> */}
            </Grid>
          </form>
        )
      }}
    </Form>
  )
}
