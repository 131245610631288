import React, { useEffect, useState } from 'react'
import Header from './components/Header/Header'
import { Form } from 'react-final-form'
import { useReportsInventoryListMutation, useReportsInventoryMutation } from '../../../services'
import { getCurrentDate } from '../../../utils/general'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import InventoryAgingTableBlock from './components/InventoryAgingTableBlock/InventoryAgingTableBlock'
import { UspReportInventoryAgingResult, UspReportInventoryListResult } from '../../../models'
import FilteringModal from './components/FilteringModal/FilteringModal'
import { appRoute } from '../../../constants'
import { useNavigate } from 'react-router-dom'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { Card, Container } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const InsightsInventoryList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userAccess = useSelector(getUserAccessRights)
  const [showTable, setShowTable] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(true)
  const [formValues, setFormValues] = useState<any>(null)

  const [filterInventory, { data: filterData, isSuccess: filterIsSuccess, isLoading: filterIsLoading }] = useReportsInventoryListMutation()

  const handleOpenFilterModal = () => setOpenFilterModal(true)
  const handleCloseFilterModal = () => setOpenFilterModal(false)

  const handleSubmit = (values: any) => {
    const jsonBranchList = values?.Branch?.map((item: any) => ({ BranchID: item?.BranchID }))
    const jsonStatusList = values?.Status?.map((item: any) => ({ CarStatusID: item?.ID }))
    filterInventory({
      uspReportInventoryList: {
        asOfDate: getCurrentDate(),
        isBasedOnAvailableForSaleDate: values.IsAvailable || false,
        jsonBranchList: jsonBranchList || null,
        jsonStatusList: jsonStatusList || null,
      },
    })
    setFormValues(values)
  }

  useEffect(() => {
    if (filterIsSuccess) {
      handleCloseFilterModal()
      setShowTable(true)
      dispatch(enqueueNotification({ message: 'Success' }))
    }
  }, [filterIsSuccess])

  useEffect(() => {
    if (!userAccess.CanAccessInventoryInsights) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Reports'
        links={[
          {
            href: appRoute.Insights,
            name: 'List',
          },
          {
            name: 'Inventory List',
          },
        ]}
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Card
        sx={{
          height: { xs: 800, md: 2 },
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        <Form onSubmit={handleSubmit} keepDirtyOnReinitialize initialValues={{ IsAvailable: false }}>
          {({ handleSubmit }: any) => (
            <form onSubmit={handleSubmit}>
              {showTable && <Header handleOpenFilterModal={handleOpenFilterModal} filterData={filterData as UspReportInventoryListResult[]} />}
              <FilteringModal formValues={formValues} open={openFilterModal} handleClose={handleCloseFilterModal} />
              {showTable && <InventoryAgingTableBlock filterData={filterData as UspReportInventoryListResult[]} isLoading={filterIsLoading} />}
            </form>
          )}
        </Form>
      </Card>
    </Container>
  )
}

export default InsightsInventoryList
