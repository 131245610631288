import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { appRoute } from '../../../constants'
import Iconify from '../../../components/iconify'
import BuyerItem from '../../Leads/LeadsList/components/buyer-item'
import EditIcon from '@mui/icons-material/Edit'
import { Key, useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { usePopover } from '../../../components/custom-popover'
import CustomPopover from '../../../components/custom-popover/custom-popover'
import VehicleItem from './components/vehicle-item'
import Label from '../../../components/label'
import {
  useAddDealsFlagMutation,
  useDeleteDealsFlagMutation,
  useEditDealsFlagMutation,
  useGetDealsFlagMutation,
  useGetTimeMutation,
} from '../../../services'
import { GetDealsFlagApiResponse, UspDealFlagViewResult } from '../../../models'
import { fetchCurrentDateTimeFromServer, formatNumber, getCurrentDate } from '../../../utils/general'
import ConfirmDialog from '../../../components/ConfirmDialog'
import AddEditFlagModal from './components/AddEditFlagModal/AddEditFlagModal'
import { convertDateToDisplay, convertDateToString } from '../../../utils/dateUtils'
import SettingsForm from '../AccountsReferenceAddEdit/SettingsForm/SettingsForm'
import SettingsCardEdit from './components/SettingsCardEdit/SettingsCardEdit'
import BalancesCard from './components/BalancesCard/BalancesCard'
import { useSnackbar } from '../../../components/snackbar'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { useDispatch } from 'react-redux'

export default function AccountsGeneral({ data, getAccountsOverview, pastDue, payOff, setOpenDueAmountsModal }: any) {
  const [openSettingsModal, setOpenSettingsModal] = useState(false)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false)
  const [referenceDeleteConfirmDialog, setReferenceDeleteConfirmDialog] = useState(false)
  const [stipulationOpenDeleteDialog, setStipulationOpenDeleteDialog] = useState(false)
  const [openStipulationModal, setOpenStipulationModal] = useState(false)
  const [stipulationToEdit, setStipulationToEdit] = useState<any>()
  const [flagOpenDeleteModal, setFlagOpenDeleteModal] = useState<boolean>(false)
  const [isAddEditFlagModalOpen, setIsAddEditFlagModalOpen] = useState<boolean>(false)
  const [getAccountFlags, { data: accountFlagsData, isLoading: accountFlagsIsLoading, isSuccess: accountsFlagsIsSuccess }] = useGetDealsFlagMutation()
  const [deleteAccountFlags, { data: deleteAccount, isSuccess: deleteAccountFlagIsSuccess, isLoading: deleteAccountFlagsIsLoading }] =
    useDeleteDealsFlagMutation()
  const [addAccountFlags, { isSuccess: addAccountFlagIsSuccess, isLoading: addAccountFlagsIsLoading }] = useAddDealsFlagMutation()
  const [editAccountFlags, { isSuccess: editAccountFlagIsSuccess, isLoading: editAccountFlagsIsLoading }] = useEditDealsFlagMutation()
  const [flagList, setFlagList] = useState<GetDealsFlagApiResponse | undefined>([])
  const [seletedVehicle, setSelectedVehicle] = useState<any>()
  const [selectedFlag, setSelectedFlag] = useState<any>()
  const [seletedReference, setSelectedReference] = useState<any>()
  const [selectedStipulation, setSelectedStipulation] = useState<any>()
  const [isPullCreditModalOpen, setIsPullCreditModalOpen] = useState(false)
  const [userSelected, setUserSelected] = useState(0)
  const navigate = useNavigate()
  const { dealID, leadID } = useParams()
  const popover = usePopover()
  const flagPopover = usePopover()
  const referencePopover = usePopover()
  const stipulationPopover = usePopover()
  const vehiclePopover = usePopover()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [getTime] = useGetTimeMutation()

  const handleDeleteFlag = (flag: UspDealFlagViewResult | undefined) => {
    if (flag?.FlagID) {
      deleteAccountFlags({
        dealId: dealID?.toString() as string,
        flagId: flag.FlagID.toString(),
      })
    }
  }

  useEffect(() => {
    if (deleteAccountFlagIsSuccess) dispatch(enqueueNotification({ message: deleteAccount?.Response }))
  }, [deleteAccountFlagIsSuccess])

  useEffect(() => {
    if (dealID) {
      fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
        getAccountFlags({
          dealId: dealID?.toString() as string,
          uspDealFlag: { effectiveDate: convertDateToString(currentDate as string) ?? '' },
        })
      })
    }
  }, [dealID, addAccountFlagIsSuccess, editAccountFlagIsSuccess, deleteAccountFlagIsSuccess])

  useEffect(() => {
    if (accountsFlagsIsSuccess) {
      setFlagList(accountFlagsData)
    }
  }, [accountsFlagsIsSuccess])

  const standingCardData = useMemo(
    () => [
      { title: 'Status', value: data?.AccountStatus },
      { title: 'Deal Date', value: convertDateToDisplay(data?.DealDate) },
      data?.DealType !== 'Wholesale' && data?.DealType !== 'Cash' ? { title: 'Next Due', value: convertDateToDisplay(data?.NextDueDate) } : {},
      data?.DealType !== 'Wholesale' && data?.DealType !== 'Cash' ? { title: 'Days Late', value: data?.DaysLate } : {},
      data?.DealType !== 'Wholesale' && data?.DealType !== 'Cash'
        ? { title: 'Pickup Due Date', value: convertDateToDisplay(data?.PickupDueDate) }
        : {},
      data?.DealType !== 'Wholesale' && data?.DealType !== 'Cash' ? { title: 'Pickup Days Late', value: data?.PickupDaysLate } : {},
      data?.LateChargeDate !== data?.NextDueDate ? { title: 'Late Fee Start Date', value: convertDateToDisplay(data?.LateChargeDate) } : {},
      data?.DealType !== 'Wholesale' && data?.DealType !== 'Cash' ? { title: 'Last Paid', value: convertDateToDisplay(data?.LastPaidDate) } : {},
      data?.DealType !== 'Wholesale' && data?.DealType !== 'Cash' ? { title: 'Schedule', value: data?.PaymentSchedule } : {},
      { title: 'Payment Amount', value: formatNumber(data?.PaymentAmount) },
      {
        title: 'Past Due',
        value: (
          <Stack direction={'row'} alignItems={'center'} flexWrap={'nowrap'} spacing={2}>
            <Typography
              sx={{
                ml: 1,
              }}
              variant='body2'
            >
              {formatNumber(pastDue)}
            </Typography>
            <IconButton sx={{ margin: 0, padding: 0 }} onClick={() => setOpenDueAmountsModal(true)}>
              <Iconify icon={'mdi:information'} color={'primary.main'} />
            </IconButton>
          </Stack>
        ),
      },
      {
        title: 'Payoff',
        value: (
          <Stack direction={'row'} alignItems={'center'} flexWrap={'nowrap'} spacing={2}>
            <Typography
              sx={{
                ml: 1,
              }}
              variant='body2'
            >
              {formatNumber(payOff)}
            </Typography>
            <IconButton sx={{ margin: 0, padding: 0 }} onClick={() => setOpenDueAmountsModal(true)}>
              <Iconify icon={'mdi:information'} color={'primary.main'} />
            </IconButton>
          </Stack>
        ),
      },
    ],
    [data, pastDue, payOff],
  )

  const settingsCardData = useMemo(
    () => [
      { title: 'Branch', value: data?.BranchCode },
      { title: 'Salesperson', value: data?.SalespersonUserCode },
      { title: 'F&I Manager', value: data?.FiUserCode },
      { title: 'Collector', value: data?.CollectorUserCode },
      { title: 'Lender', value: data?.LenderCode },
      { title: 'Dealer', value: data?.DealerCode },
    ],
    [data],
  )
  return (
    <>
      <Grid container spacing={3}>
        <Grid container item xs={12} md={8} spacing={3} direction={'column'}>
          <Grid item>
            <Card sx={{ height: '100%' }}>
              <CardHeader title='Buyer' />
              <Stack spacing={2.5} sx={{ p: 3 }}>
                <BuyerItem
                  variant='outlined'
                  name={data?.BuyerName}
                  address={data?.BuyerAddressData?.[0]}
                  phoneNumber={data?.BuyerPhoneData?.[0]?.PhoneNumber}
                  email={data?.BuyerEmail}
                  // buyerStatus={data?.ApplicantStatus}
                  // buyerStatusColor={data?.ApplicantStatusColor}
                  action={
                    <IconButton
                      color={popover.open ? 'inherit' : 'default'}
                      onClick={(e) => {
                        popover.onOpen(e)
                        setUserSelected(data.ContactID1)
                      }}
                      sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                      <Iconify icon='eva:more-vertical-fill' />
                    </IconButton>
                  }
                  sx={{
                    p: 2.5,
                    borderRadius: 1,
                  }}
                />
                {data?.CoBuyerName && (
                  <BuyerItem
                    variant='outlined'
                    name={data?.CoBuyerName}
                    address={data?.CoBuyerAddressData[0]}
                    phoneNumber={data?.CoBuyerPhoneData?.[0]?.PhoneNumber}
                    email={data?.CoBuyerEmail}
                    action={
                      <IconButton
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          popover.onOpen(event)

                          setUserSelected(data?.ContactID2)
                          // console.log(buyer.id)
                        }}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                      >
                        <Iconify icon='eva:more-vertical-fill' />
                      </IconButton>
                    }
                    sx={{
                      p: 2.5,
                      borderRadius: 1,
                    }}
                  />
                )}
              </Stack>
            </Card>
          </Grid>

          <Grid item>
            <Card sx={{ height: '100%' }}>
              <CardHeader
                title='Vehicle'
                //   action={
                //     <Button
                //       size='small'
                //       color='primary'
                //       onClick={() => {
                //         navigate(
                //           appRoute.LeadsReferenceAddEdit.replace(':leadID', `${leadID}`)
                //             .replace(':contactID', `${data?.ContactID1}`)
                //             .replace(':referenceID', 'add'),
                //         )
                //       }}
                //       startIcon={<Iconify icon='mingcute:add-line' />}
                //     >
                //       Reference
                //     </Button>
                //   }
              />
              <Stack spacing={2.5} sx={{ p: 3 }}>
                <VehicleItem
                  key={data?.CarID}
                  variant='outlined'
                  name={data?.CarDescription}
                  stock={data?.StockNumber}
                  vin={data?.VIN}
                  action={
                    <IconButton
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setSelectedVehicle(data.CarID)
                        vehiclePopover.onOpen(event)
                      }}
                      sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                      <Iconify icon='eva:more-vertical-fill' />
                    </IconButton>
                  }
                  sx={{
                    p: 2.5,
                    borderRadius: 1,
                  }}
                />
              </Stack>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ height: '100%' }}>
              <CardHeader
                title='Flags'
                action={
                  <Button
                    size='small'
                    color='primary'
                    onClick={(e) => {
                      setSelectedFlag(undefined)
                      setIsAddEditFlagModalOpen(true)
                    }}
                    startIcon={<Iconify icon='mingcute:add-line' />}
                  >
                    {isMobile ? '' : 'Flag'}
                  </Button>
                }
              />
              <Stack spacing={2.5} sx={{ p: 3 }}>
                {flagList?.map((item: { FlagID: Key | null | undefined; Flag: string | undefined; UserCode: any }) => (
                  <BuyerItem
                    key={item.FlagID}
                    variant='outlined'
                    name={item.Flag}
                    addedBy={item.UserCode}
                    action={
                      item.FlagID ? (
                        <IconButton
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            setSelectedFlag(item)
                            flagPopover.onOpen(event)
                          }}
                          sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                          <Iconify icon='eva:more-vertical-fill' />
                        </IconButton>
                      ) : null
                    }
                    sx={{
                      p: 2.5,
                      borderRadius: 1,
                    }}
                  />
                ))}
              </Stack>
            </Card>
          </Grid>

          <Grid item>
            <Card>
              <CardHeader
                title='References'
                action={
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      data?.ContactID1
                        ? navigate(
                            generatePath(appRoute.AccountsReferencesAddEdit, {
                              dealID,
                              contactID: data?.ContactID1,
                              referenceID: 'add',
                            }),
                          )
                        : dispatch(
                            enqueueNotification({
                              message: 'Must enter buyer information first.',
                              options: {
                                variant: 'error',
                              },
                            }),
                          )
                    }}
                    startIcon={<Iconify icon='mingcute:add-line' />}
                  >
                    {isMobile ? '' : 'Reference'}
                  </Button>
                }
              />
              <Stack spacing={2.5} sx={{ p: 3 }}>
                {data?.ReferenceData?.map(
                  (reference: {
                    ReferenceID: Key | null | undefined
                    Name: string | undefined
                    address: any
                    PhoneNumber: string | undefined
                    RelationshipType: string | undefined
                    IsVerified: boolean | undefined
                    id: any
                  }) => (
                    <BuyerItem
                      key={reference.ReferenceID}
                      variant='outlined'
                      name={reference.Name}
                      address={reference.address}
                      phoneNumber={reference.PhoneNumber}
                      relationship={reference.RelationshipType}
                      IsVerified={reference.IsVerified}
                      isReference={true}
                      action={
                        <IconButton
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            console.log(reference.id)
                            setSelectedReference(reference)
                            referencePopover.onOpen(event)
                          }}
                          sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                          <Iconify icon='eva:more-vertical-fill' />
                        </IconButton>
                      }
                      sx={{
                        p: 2.5,
                        borderRadius: 1,
                      }}
                    />
                  ),
                )}
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={4} spacing={3} direction={'column'}>
          <Grid item>
            <Card>
              <CardHeader title='Standing' />
              <Box sx={{ p: 3 }} display={'flex'} flexDirection={'column'}>
                {standingCardData.map((el) =>
                  typeof el !== 'boolean' && el?.value ? (
                    <Box display={'flex'} alignItems={'baseline'}>
                      <Typography sx={{ py: 1 }} variant='body2'>
                        {el?.title} :
                      </Typography>

                      {typeof el?.value === 'string' || typeof el?.value === 'number' ? (
                        <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                          {el?.value}
                        </Typography>
                      ) : (
                        el?.value
                      )}
                    </Box>
                  ) : (
                    ''
                  ),
                )}
              </Box>
            </Card>
          </Grid>

          <Grid item>
            <BalancesCard balancesData={data?.BalanceData} />
          </Grid>
          <Grid item>
            <Card sx={{ height: '100%' }}>
              <CardHeader
                title='Settings'
                action={
                  <IconButton onClick={() => setOpenSettingsModal(true)}>
                    <Iconify icon='solar:pen-bold' />
                  </IconButton>
                }
              />
              <Box sx={{ p: 3 }} display={'flex'} flexDirection={'column'}>
                {settingsCardData.map(
                  (el) =>
                    el?.value && (
                      <Box display={'flex'} alignItems={'baseline'}>
                        <Typography sx={{ py: 1 }} variant='body2'>
                          {el?.title} :
                        </Typography>

                        {typeof el?.value === 'string' ? (
                          <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                            {el?.value}
                          </Typography>
                        ) : (
                          el?.value
                        )}
                      </Box>
                    ),
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        open={flagOpenDeleteModal}
        onOk={() => {
          handleDeleteFlag(selectedFlag)
          setFlagOpenDeleteModal(false)
        }}
        handleClose={() => setFlagOpenDeleteModal(false)}
        notification={'Are you sure you wish to delete this flag? This action is irreversible!'}
      />
      <CustomPopover open={referencePopover.open} onClose={referencePopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={() =>
            navigate(
              generatePath(appRoute.AccountsReferencesAddEdit, {
                dealID,
                contactID: data.ContactID1,
                referenceID: `${seletedReference.ReferenceID}`,
              }),
            )
          }
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setReferenceDeleteConfirmDialog(true)
            referencePopover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
      <CustomPopover open={vehiclePopover.open} onClose={vehiclePopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            navigate(appRoute?.InventoryEdit.replace(':carID', seletedVehicle as string))
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
      </CustomPopover>
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            navigate(
              appRoute.AccountsBuyerEdit.replace(':dealID', dealID as string)
                .replace(':contactID', userSelected?.toString() || 'add')
                .replace(':contactType', `${userSelected === data?.ContactID2 ? 'co-buyer' : 'buyer'}`),
            )
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        {/* {userSelected === data?.ContactID2 && (
          <MenuItem onClick={() => setDeleteConfirmDialog(true)} sx={{ color: 'error.main' }}>
            <Iconify icon='solar:trash-bin-trash-bold' />
            Delete
          </MenuItem>
        )} */}
      </CustomPopover>
      <CustomPopover open={flagPopover.open} onClose={flagPopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            setIsAddEditFlagModalOpen(true)
            flagPopover.onClose()
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setFlagOpenDeleteModal(true)
            flagPopover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
      <CustomPopover open={stipulationPopover.open} onClose={stipulationPopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            if (selectedStipulation) {
              setStipulationToEdit(selectedStipulation)
            }
            setOpenStipulationModal(true)
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem onClick={() => setStipulationOpenDeleteDialog(true)} sx={{ color: 'error.main' }}>
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
      <Dialog
        open={isAddEditFlagModalOpen as boolean}
        onClose={() => {
          setSelectedFlag && setSelectedFlag(undefined)
          setIsAddEditFlagModalOpen && setIsAddEditFlagModalOpen(false)
        }}
      >
        <DialogTitle />
        <DialogContent>
          <AddEditFlagModal
            setIsAddEditFlagModalOpen={setIsAddEditFlagModalOpen}
            editAccountFlags={editAccountFlags as (e: any) => void}
            selectedFlag={selectedFlag}
            isAddEditFlagModalOpen={isAddEditFlagModalOpen as boolean}
            addAccountFlags={addAccountFlags as (e: any) => void}
          />
        </DialogContent>
        <DialogActions />
      </Dialog>
      <Dialog open={openSettingsModal} onClose={() => setOpenSettingsModal(false)}>
        {/* <SettingsForm salesPersonID={data?.SalespersonID} managerID={data?.FiUserID} taskDeal={data} closeModal={() => setOpenSettingsModal(false)} /> */}
        <SettingsCardEdit
          handleClose={() => setOpenSettingsModal(false)}
          lenderID={data?.LenderID}
          dealerID={data?.DealerID}
          collectorID={data?.CollectorUserID}
          salesPersonID={data?.SalespersonUserID}
          managerID={data?.FiUserID}
          getAccountsOverview={getAccountsOverview}
        />
      </Dialog>
    </>
  )
}
