import { storageGet, storageSet } from '../../../../../utils/storage'
import { UspReportPaymentDetailsResult, UspReportPaymentResult } from '../../../../../models'
import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  generateUtilityClasses,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { formatNumber } from '../../../../../utils/general'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: theme.spacing(3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    // width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },

  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

interface Props {
  filterData: UspReportPaymentResult[]
  isLoading: boolean
}

const AccountingPaymentsTableBlock = ({ filterData, isLoading }: Props) => {
  const initialOrder = (storageGet('reports_accounting_payments_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('reports_accounting_payments_order_by') || 'Cashier') as keyof UspReportPaymentDetailsResult
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = useState<keyof UspReportPaymentDetailsResult>(initialOrderBy)

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspReportPaymentDetailsResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('reports_accounting_payments_order', isAsc ? 'desc' : 'asc')
    storageSet('reports_accounting_payments_order_by', property)
  }

  const createSortHandler = (property: keyof UspReportPaymentDetailsResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 118px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Date' ? order : false}>
              <TableSortLabel active={orderBy === 'Date'} direction={orderBy === 'Date' ? order : 'asc'} onClick={createSortHandler('Date')}>
                Date
                {orderBy === 'Date' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Branch' ? order : false}>
              <TableSortLabel active={orderBy === 'Branch'} direction={orderBy === 'Branch' ? order : 'asc'} onClick={createSortHandler('Branch')}>
                Branch
                {orderBy === 'Branch' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StockNumber'}
                direction={orderBy === 'StockNumber' ? order : 'asc'}
                onClick={createSortHandler('StockNumber')}
              >
                Stock Number
                {orderBy === 'StockNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Receipt' ? order : false}>
              <TableSortLabel active={orderBy === 'Receipt'} direction={orderBy === 'Receipt' ? order : 'asc'} onClick={createSortHandler('Receipt')}>
                Receipt
                {orderBy === 'Receipt' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Payee' ? order : false}>
              <TableSortLabel active={orderBy === 'Payee'} direction={orderBy === 'Payee' ? order : 'asc'} onClick={createSortHandler('Payee')}>
                Payee
                {orderBy === 'Payee' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DistributionType' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DistributionType'}
                direction={orderBy === 'DistributionType' ? order : 'asc'}
                onClick={createSortHandler('DistributionType')}
              >
                Distribution Type
                {orderBy === 'DistributionType' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Form' ? order : false}>
              <TableSortLabel active={orderBy === 'Form'} direction={orderBy === 'Form' ? order : 'asc'} onClick={createSortHandler('Form')}>
                Form
                {orderBy === 'Form' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Method' ? order : false}>
              <TableSortLabel active={orderBy === 'Method'} direction={orderBy === 'Method' ? order : 'asc'} onClick={createSortHandler('Method')}>
                Method
                {orderBy === 'Method' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'AppliedAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'AppliedAmount'}
                direction={orderBy === 'AppliedAmount' ? order : 'asc'}
                onClick={createSortHandler('AppliedAmount')}
              >
                Applied Amount
                {orderBy === 'AppliedAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'ChargebackCollected' ? order : false}>
              <TableSortLabel
                active={orderBy === 'ChargebackCollected'}
                direction={orderBy === 'ChargebackCollected' ? order : 'asc'}
                onClick={createSortHandler('ChargebackCollected')}
              >
                Chargeback Collected
                {orderBy === 'ChargebackCollected' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'LateChargeCollected' ? order : false}>
              <TableSortLabel
                active={orderBy === 'LateChargeCollected'}
                direction={orderBy === 'LateChargeCollected' ? order : 'asc'}
                onClick={createSortHandler('LateChargeCollected')}
              >
                Late Charge Collected
                {orderBy === 'LateChargeCollected' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'InterestCollected' ? order : false}>
              <TableSortLabel
                active={orderBy === 'InterestCollected'}
                direction={orderBy === 'InterestCollected' ? order : 'asc'}
                onClick={createSortHandler('InterestCollected')}
              >
                Interest Collected
                {orderBy === 'InterestCollected' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'SalesTax' ? order : false}>
              <TableSortLabel
                active={orderBy === 'SalesTax'}
                direction={orderBy === 'SalesTax' ? order : 'asc'}
                onClick={createSortHandler('SalesTax')}
              >
                Sales Tax
                {orderBy === 'SalesTax' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CPI1' ? order : false}>
              <TableSortLabel active={orderBy === 'CPI1'} direction={orderBy === 'CPI1' ? order : 'asc'} onClick={createSortHandler('CPI1')}>
                CPI1
                {orderBy === 'CPI1' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CPI2' ? order : false}>
              <TableSortLabel active={orderBy === 'CPI2'} direction={orderBy === 'CPI2' ? order : 'asc'} onClick={createSortHandler('CPI2')}>
                CPI2
                {orderBy === 'CPI2' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Principal' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Principal'}
                direction={orderBy === 'Principal' ? order : 'asc'}
                onClick={createSortHandler('Principal')}
              >
                Principal
                {orderBy === 'Principal' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DiscountEarned' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DiscountEarned'}
                direction={orderBy === 'DiscountEarned' ? order : 'asc'}
                onClick={createSortHandler('DiscountEarned')}
              >
                Discount Earned
                {orderBy === 'DiscountEarned' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'InterestRebate' ? order : false}>
              <TableSortLabel
                active={orderBy === 'InterestRebate'}
                direction={orderBy === 'InterestRebate' ? order : 'asc'}
                onClick={createSortHandler('InterestRebate')}
              >
                Interest Rebate
                {orderBy === 'InterestRebate' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'InsuranceRebate' ? order : false}>
              <TableSortLabel
                active={orderBy === 'InsuranceRebate'}
                direction={orderBy === 'InsuranceRebate' ? order : 'asc'}
                onClick={createSortHandler('InsuranceRebate')}
              >
                Insurance Rebate
                {orderBy === 'InsuranceRebate' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Transaction' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Transaction'}
                direction={orderBy === 'Transaction' ? order : 'asc'}
                onClick={createSortHandler('Transaction')}
              >
                Transaction
                {orderBy === 'Transaction' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), filterData as any[]).map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{convertDateToDisplay(item.Date)}</TableCell>
              <TableCell>{item.Branch}</TableCell>
              <TableCell>{item.StockNumber}</TableCell>
              <TableCell>{item.Receipt}</TableCell>
              <TableCell>{item.Payee}</TableCell>
              <TableCell>{item.DistributionType}</TableCell>
              <TableCell>{item.Form}</TableCell>
              <TableCell>{item.Method}</TableCell>
              <TableCell>{item.AppliedAmount}</TableCell>
              <TableCell>{item.ChargebackCollected}</TableCell>
              <TableCell>{item.LateChargeCollected}</TableCell>
              <TableCell>{item.InterestCollected}</TableCell>
              <TableCell>{formatNumber(item.SalesTax)}</TableCell>
              <TableCell>{item.CPI1}</TableCell>
              <TableCell>{item.CPI2}</TableCell>
              <TableCell>{item.Principal}</TableCell>
              <TableCell>{item.DiscountEarned}</TableCell>
              <TableCell>{item.InterestRebate}</TableCell>
              <TableCell>{item.InsuranceRebate}</TableCell>
              <TableCell>{item.Transaction}</TableCell>
            </TableRow>
          ))}
          {filterData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AccountingPaymentsTableBlock
