import React from 'react'
import { Box, Grid, generateUtilityClasses, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { stableSort, getComparator } from '../../../../../utils/sorting'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { UspReportInventoryListResult } from '../../../../../models'
import { formatNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'tableHead', 'tableCellName'])

interface Props {
  filterData: UspReportInventoryListResult[]
  isLoading: boolean
}

const InventoryAgingTableBlock = ({ filterData, isLoading }: Props) => {
  const navigate = useNavigate()
  const initialOrder = (storageGet('reports_inventory_aging_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('reports_inventory_aging_order_by') || 'StockNumber') as keyof UspReportInventoryListResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspReportInventoryListResult>(initialOrderBy)

  const handleEdit = (id: number | string) => {
    if (id) {
      navigate(appRoute.InventoryEdit.replace(':carID', String(id)))
    }
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspReportInventoryListResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('reports_inventory_aging_order', isAsc ? 'desc' : 'asc')
    storageSet('reports_inventory_aging_order_by', property)
  }

  const createSortHandler = (property: keyof UspReportInventoryListResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 118px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StockNumber'}
                direction={orderBy === 'StockNumber' ? order : 'asc'}
                onClick={createSortHandler('StockNumber')}
              >
                Stock #
                {orderBy === 'StockNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Branch' ? order : false}>
              <TableSortLabel active={orderBy === 'Branch'} direction={orderBy === 'Branch' ? order : 'asc'} onClick={createSortHandler('Branch')}>
                Branch
                {orderBy === 'Branch' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Status' ? order : false}>
              <TableSortLabel active={orderBy === 'Status'} direction={orderBy === 'Status' ? order : 'asc'} onClick={createSortHandler('Status')}>
                Status
                {orderBy === 'Status' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Description' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Description'}
                direction={orderBy === 'Description' ? order : 'asc'}
                onClick={createSortHandler('Description')}
              >
                Description
                {orderBy === 'Description' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Trim' ? order : false}>
              <TableSortLabel active={orderBy === 'Trim'} direction={orderBy === 'Trim' ? order : 'asc'} onClick={createSortHandler('Trim')}>
                Trim
                {orderBy === 'Trim' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'BodyType' ? order : false}>
              <TableSortLabel
                active={orderBy === 'BodyType'}
                direction={orderBy === 'BodyType' ? order : 'asc'}
                onClick={createSortHandler('BodyType')}
              >
                Body
                {orderBy === 'BodyType' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'VIN' ? order : false}>
              <TableSortLabel active={orderBy === 'VIN'} direction={orderBy === 'VIN' ? order : 'asc'} onClick={createSortHandler('VIN')}>
                VIN
                {orderBy === 'VIN' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Mileage' ? order : false}>
              <TableSortLabel active={orderBy === 'Mileage'} direction={orderBy === 'Mileage' ? order : 'asc'} onClick={createSortHandler('Mileage')}>
                Mileage
                {orderBy === 'Mileage' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Color' ? order : false}>
              <TableSortLabel active={orderBy === 'Color'} direction={orderBy === 'Color' ? order : 'asc'} onClick={createSortHandler('Color')}>
                Color
                {orderBy === 'Color' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Engine' ? order : false}>
              <TableSortLabel active={orderBy === 'Engine'} direction={orderBy === 'Engine' ? order : 'asc'} onClick={createSortHandler('Engine')}>
                Engine
                {orderBy === 'Engine' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Transmission' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Transmission'}
                direction={orderBy === 'Transmission' ? order : 'asc'}
                onClick={createSortHandler('Transmission')}
              >
                Transmission
                {orderBy === 'Transmission' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Fuel' ? order : false}>
              <TableSortLabel active={orderBy === 'Fuel'} direction={orderBy === 'Fuel' ? order : 'asc'} onClick={createSortHandler('Fuel')}>
                Fuel
                {orderBy === 'Fuel' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Cylinders' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Cylinders'}
                direction={orderBy === 'Cylinders' ? order : 'asc'}
                onClick={createSortHandler('Cylinders')}
              >
                Cylinders
                {orderBy === 'Cylinders' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Condition' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Condition'}
                direction={orderBy === 'Condition' ? order : 'asc'}
                onClick={createSortHandler('Condition')}
              >
                Condition
                {orderBy === 'Condition' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DaysAvailable' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DaysAvailable'}
                direction={orderBy === 'DaysAvailable' ? order : 'asc'}
                onClick={createSortHandler('DaysAvailable')}
              >
                Days Available
                {orderBy === 'DaysAvailable' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'PurchaseType' ? order : false}>
              <TableSortLabel
                active={orderBy === 'PurchaseType'}
                direction={orderBy === 'PurchaseType' ? order : 'asc'}
                onClick={createSortHandler('PurchaseType')}
              >
                Purchase Type
                {orderBy === 'PurchaseType' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'BV1' ? order : false}>
              <TableSortLabel active={orderBy === 'BV1'} direction={orderBy === 'BV1' ? order : 'asc'} onClick={createSortHandler('BV1')}>
                BV1
                {orderBy === 'BV1' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'BV2' ? order : false}>
              <TableSortLabel active={orderBy === 'BV2'} direction={orderBy === 'BV2' ? order : 'asc'} onClick={createSortHandler('BV2')}>
                BV2
                {orderBy === 'BV2' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StickerPrice' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StickerPrice'}
                direction={orderBy === 'StickerPrice' ? order : 'asc'}
                onClick={createSortHandler('StickerPrice')}
              >
                Sticker Price
                {orderBy === 'StickerPrice' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CashDown' ? order : false}>
              <TableSortLabel
                active={orderBy === 'CashDown'}
                direction={orderBy === 'CashDown' ? order : 'asc'}
                onClick={createSortHandler('CashDown')}
              >
                Cash Down
                {orderBy === 'CashDown' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DepositAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DepositAmount'}
                direction={orderBy === 'DepositAmount' ? order : 'asc'}
                onClick={createSortHandler('DepositAmount')}
              >
                Deposit Amount
                {orderBy === 'DepositAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), filterData as any[]).map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell className={classes.tableCellName} onClick={() => handleEdit(item.CarID as string)}>
                {item.StockNumber}
              </TableCell>
              <TableCell>{item?.Branch ?? '-'}</TableCell>
              <TableCell>{item?.Status ?? '-'}</TableCell>
              <TableCell>{item?.Description ?? '-'}</TableCell>
              <TableCell>{item?.Trim ?? '-'}</TableCell>
              <TableCell>{item?.BodyType ?? '-'}</TableCell>
              <TableCell>{item?.VIN ?? '-'}</TableCell>
              <TableCell>{item?.Mileage ?? '-'}</TableCell>
              <TableCell>{item?.Color ?? '-'}</TableCell>
              <TableCell>{item?.Engine ?? '-'}</TableCell>
              <TableCell>{item?.Transmission ?? '-'}</TableCell>
              <TableCell>{item?.Fuel ?? '-'}</TableCell>
              <TableCell>{item?.Cylinders ?? '-'}</TableCell>
              <TableCell>{item?.Condition ?? '-'}</TableCell>
              <TableCell>{item?.DaysAvailable ?? '-'}</TableCell>
              <TableCell>{item?.PurchaseType ?? '-'}</TableCell>
              <TableCell>{item?.BV1 ?? '-'}</TableCell>
              <TableCell>{item?.BV2 ?? '-'}</TableCell>
              <TableCell>{formatNumber(item?.StickerPrice as string) ?? '-'}</TableCell>
              <TableCell>{item?.CashDown ?? '-'}</TableCell>
              <TableCell>{formatNumber(item?.DepositAmount as string) ?? '-'}</TableCell>
            </TableRow>
          ))}
          {filterData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default InventoryAgingTableBlock
