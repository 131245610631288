import type { Middleware } from '@reduxjs/toolkit'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import { enqueueNotification } from '../slices/notificationsSlice'
import { onLogout } from '../slices/authSlice'



export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      const serverResponse = action.payload?.data
      // console.log('hello')
      // console.log('serverResponse',serverResponse)
     
      triggerError(serverResponse)
      const responseCode = action.payload?.status
      if (responseCode === 401) {
        // Unauthorized
        dispatch(onLogout())
      }
    }

    function triggerError(serverResponse:any){


      if(typeof serverResponse ===  'string'){
        dispatch(
          enqueueNotification({
            message: serverResponse,
            options: {
              variant: 'error',
            },
          }),
        )
      }
      else if (typeof serverResponse === 'object'  ) {
      console.log(serverResponse)

        Object.values(serverResponse)?.forEach((error:any) => {
          if (error){
          if (typeof serverResponse === 'object'  ){
            return triggerError(error)
          }
          dispatch(
            enqueueNotification({
              message: error?.message || error,
              options: {
                variant: 'error',
              },
            }),
          )}
        })
      } else {
        const response = action.payload?.data?.Response
        const errors = action.payload?.data?.errors
        // console.log(errors,errors)
        // console.log('Object.values(errors[0])[0]',Object.values(errors)[0])
        const detail = action.payload?.data?.detail
        const message = action.error?.message
        if (detail || message) {
          dispatch(
            enqueueNotification({
              message: response || Object.values(errors[0])[0] || detail || message,
              options: {
                variant: 'error',
              },
            }),
          )
        }
      }
    }

    return next(action)
  }
