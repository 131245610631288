import React, { useEffect, useState } from 'react'
import {
  Grid,
  generateUtilityClasses,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TableSortLabel,
  Box,
  Tooltip,
  Typography,
  Popover,
  useTheme,
  Button,
  Stack,
  ListItemText,
  MenuItem,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { UspCarPaymentViewResult, UspGetDataCar, UspPaymentViewDetailResult } from '../../../../../models'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { convertDateTimeToDisplay, convertDateToDisplay } from '../../../../../utils/dateUtils'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import { useAddPaymentsTransactionMutation } from '../../../../../services'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import Label from '../../../../../components/label'
import CustomPopover from '../../../../../components/custom-popover'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'inactiveItem', 'loading', 'tableHead'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(0, 3, 3, 3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: '#F7685B',
    borderRadius: '4px',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
}))

interface Props {
  loading: boolean
  inventoryCarPaymentData: UspCarPaymentViewResult[]
  carData: UspGetDataCar
  getCarPayment: (e: any) => void
  includeVoidedPayments: boolean
}
// TODO: CHECK IF THIS IS NEEDED
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.main,
    maxWidth: '500px',
    padding: theme.spacing(2),
    color: theme.palette?.text?.primary,
    border: `1px solid ${theme.palette.background.secondary}`,
  },
}))

type actionType = 'Chargeback/NSF' | 'Void/Reverse'

const InventoryPaymentsTableBlock = ({ inventoryCarPaymentData, loading, carData, getCarPayment, includeVoidedPayments }: Props) => {
  const [addPaymentTransaction, { isSuccess: addPaymentIsSuccess }] = useAddPaymentsTransactionMutation()
  const initialOrder = (storageGet('inventory_payments_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('inventory_payments_by') || 'PaymentDate') as keyof UspCarPaymentViewResult
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = useState<keyof UspCarPaymentViewResult>(initialOrderBy)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [confirmDialog, setConfirmDialog] = useState<undefined | actionType>(undefined)
  const [openPopoverData, setOpenPopoverData] = useState<UspPaymentViewDetailResult | null>(null)
  const [actionType, setActionType] = useState<actionType>()

  const theme = useTheme()
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleMenuOptionAction = () => {
    addPaymentTransaction({
      paymentsTransaction: {
        paymentID: openPopoverData?.PaymentID as number,
        dealID: carData?.CarID,
        branchID: carData?.BranchID as number,
        isReversal: actionType === 'Void/Reverse',
        isChargeback: actionType === 'Chargeback/NSF',
        chargebackFee: null,
        chargebackBankCoaID: null,
        isRestrictCheckACH: true,
      },
    })
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspCarPaymentViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('inventory_payments_order', isAsc ? 'desc' : 'asc')
    storageSet('inventory_payments_by', property)
  }

  const createSortHandler = (property: keyof UspCarPaymentViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleMoreVertClick = (event: React.MouseEvent<HTMLElement>, item: UspPaymentViewDetailResult) => {
    setAnchorEl(event.currentTarget)
    setOpenPopoverData(item)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenPopoverData(null)
  }

  const handleAccountPaymentAction = (menuOption: actionType) => {
    setActionType(menuOption)
    setConfirmDialog(menuOption)
  }

  useEffect(() => {
    if (addPaymentIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      getCarPayment({
        carId: carData?.CarID,
        uspCarPaymentView: {
          isIncludeReversals: includeVoidedPayments,
        },
      })
      setConfirmDialog(undefined)
      setOpenPopoverData(null)
      setAnchorEl(null)
    }
  }, [includeVoidedPayments, addPaymentIsSuccess])

  return (
    <>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'PaymentDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'PaymentDate'}
                  direction={orderBy === 'PaymentDate' ? order : 'asc'}
                  onClick={createSortHandler('PaymentDate')}
                >
                  Effective
                  {orderBy === 'PaymentDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'SystemDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'SystemDate'}
                  direction={orderBy === 'SystemDate' ? order : 'asc'}
                  onClick={createSortHandler('SystemDate')}
                >
                  Actual
                  {orderBy === 'SystemDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Cashier' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'Cashier'}
                  direction={orderBy === 'Cashier' ? order : 'asc'}
                  onClick={createSortHandler('Cashier')}
                >
                  Cashier
                  {orderBy === 'Cashier' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'PaymentType' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'PaymentType'}
                  direction={orderBy === 'PaymentType' ? order : 'asc'}
                  onClick={createSortHandler('PaymentType')}
                >
                  Type
                  {orderBy === 'PaymentType' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'PaymentForm' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'PaymentForm'}
                  direction={orderBy === 'PaymentForm' ? order : 'asc'}
                  onClick={createSortHandler('PaymentForm')}
                >
                  Form
                  {orderBy === 'PaymentForm' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableHead} sortDirection={orderBy === 'ReceiptNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'ReceiptNumber'}
                  direction={orderBy === 'ReceiptNumber' ? order : 'asc'}
                  onClick={createSortHandler('ReceiptNumber')}
                >
                  Receipt #
                  {orderBy === 'ReceiptNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableHead} sortDirection={orderBy === 'TransactionAmount' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'TransactionAmount'}
                  direction={orderBy === 'TransactionAmount' ? order : 'asc'}
                  onClick={createSortHandler('TransactionAmount')}
                >
                  Amount
                  {orderBy === 'TransactionAmount' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableHead} sortDirection={orderBy === 'ProcessingFee' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'ProcessingFee'}
                  direction={orderBy === 'ProcessingFee' ? order : 'asc'}
                  onClick={createSortHandler('ProcessingFee')}
                >
                  Processing
                  {orderBy === 'ProcessingFee' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableHead} sortDirection={orderBy === 'WriteOffAmount' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'WriteOffAmount'}
                  direction={orderBy === 'WriteOffAmount' ? order : 'asc'}
                  onClick={createSortHandler('WriteOffAmount')}
                >
                  Write Off
                  {orderBy === 'WriteOffAmount' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableHead} sortDirection={orderBy === 'PaymentStatus' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'PaymentStatus'}
                  direction={orderBy === 'PaymentStatus' ? order : 'asc'}
                  onClick={createSortHandler('PaymentStatus')}
                >
                  Status
                  {orderBy === 'PaymentStatus' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} align={'right'}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), inventoryCarPaymentData as any[])?.map((item: any, index: number) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{convertDateToDisplay(item?.PaymentDate)}</TableCell>
                <TableCell>{convertDateTimeToDisplay(item?.SystemDate)}</TableCell>
                <TableCell>{item?.Cashier}</TableCell>
                <TableCell>{item?.PaymentType}</TableCell>
                <TableCell>
                  <TableCell sx={{ alignItems: 'center' }}>
                    <ListItemText
                      primary={item?.PaymentForm}
                      secondary={item?.PaymentMethod}
                      primaryTypographyProps={{ typography: 'body2' }}
                      secondaryTypographyProps={{
                        component: 'span',
                        color: 'text.disabled',
                      }}
                    />
                  </TableCell>
                </TableCell>
                <TableCell>{item?.ReceiptNumber}</TableCell>
                <TableCell>{item?.TransactionAmount}</TableCell>
                <TableCell>{item?.ProcessingFee}</TableCell>
                <TableCell>{item?.WriteOffAmount}</TableCell>
                <TableCell>
                  <Label color={item.PaymentStatus == 'Active' ? 'success' : 'error'}>{item.PaymentStatus}</Label>
                </TableCell>
                <TableCell>
                  <IconButton onClick={(e) => handleMoreVertClick(e, item)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <CustomPopover id={id} open={anchorEl} onClose={handleClose} arrow='right-top' sx={{ width: 140 }}>
              <Stack direction={'column'}>
                <MenuItem>Print</MenuItem>
                {openPopoverData?.PaymentStatus === 'Active' && userAccess.CanVoidPayment && (
                  <MenuItem sx={{ color: 'error.main' }} onClick={() => handleAccountPaymentAction('Void/Reverse')}>
                    Void/Reverse
                  </MenuItem>
                )}
                {openPopoverData?.PaymentStatus === 'Active' && openPopoverData?.IsAllowChargeback && userAccess.CanChargebackPayment && (
                  <MenuItem onClick={() => handleAccountPaymentAction('Chargeback/NSF')}>Chargeback/NSF</MenuItem>
                )}
              </Stack>
            </CustomPopover>
            {inventoryCarPaymentData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog
        open={!!confirmDialog}
        onOk={handleMenuOptionAction}
        handleClose={() => setConfirmDialog(undefined)}
        notification={`Are you sure you want to ${actionType} this payment? This action is irreversible and will affect 
        the account balance and payment history. Please confirm to proceed.`}
      />
    </>
  )
}

// TODO: CHECK IF THIS IS NEEDED
const renderDistributionData = (distributionData: any[]) => {
  return (
    <div>
      {distributionData
        ?.filter((data: any) => Object.values(data).some((value) => value !== 0))
        .map((data: any, index: number) => (
          <div key={index}>
            {/* Render data items with non-zero values */}
            {data?.AppliedAmount !== '0.00' && <Typography>Applied Amount: {data?.AppliedAmount}</Typography>}
            {data?.CPI1 !== '0.00' && <Typography>CPI1: {data?.CPI1}</Typography>}
            {data?.CPI2 !== '0.00' && <Typography>CPI2: {data?.CPI2}</Typography>}
            {data?.ChargebackCollected !== '0.00' && <Typography>Chargeback Collected: {data?.ChargebackCollected}</Typography>}
            {data?.DiscountEarned !== '0.00' && <Typography>Discount Earned: {data?.DiscountEarned}</Typography>}
            <Typography>Distribution Type: {data?.DistributionType}</Typography>
            {data?.GrossBalanceCollected !== '0.00' && <Typography>Gross Balance Collected: {data?.GrossBalanceCollected}</Typography>}
            {data?.InsuranceRebate !== '0.00' && <Typography>Insurance Rebate: {data?.InsuranceRebate}</Typography>}
            {data?.InterestCollected !== '0.00' && <Typography>Interest Collected: {data?.InterestCollected}</Typography>}
            {data?.InterestDue !== '0.00' && <Typography>Interest Due: {data?.InterestDue}</Typography>}
            {data?.InterestRebate !== '0.00' && <Typography>Interest Rebate: {data?.InterestRebate}</Typography>}
            {data?.LateChargeCollected !== '0.00' && <Typography>Late Charge Collected: {data?.LateChargeCollected}</Typography>}
            {data?.LateChargeDue !== '0.00' && <Typography>Late Charge Due: {data?.LateChargeDue}</Typography>}
            {data?.Principal !== '0.00' && <Typography>Principal: {data?.Principal}</Typography>}
            {data?.SalesTax !== '0.00' && <Typography>Sales Tax: {data?.SalesTax}</Typography>}
          </div>
        ))}
    </div>
  )
}

export default InventoryPaymentsTableBlock
