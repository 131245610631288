import React, { Dispatch, SetStateAction } from 'react'
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  generateUtilityClasses,
} from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import { redirect, useNavigate } from 'react-router-dom'
import { UspFollowUpViewResult } from '../../../../../../../models'
import { appRoute } from '../../../../../../../constants'
import { getComparator, stableSort } from '../../../../../../../utils/sorting'
import { convertDateTimeToDisplay, convertDateToDisplay } from '../../../../../../../utils/dateUtils'
import { storageGet, storageSet } from '../../../../../../../utils/storage'

interface Props {
  followUpView: UspFollowUpViewResult[]
  loading: boolean
  selected: number[]
  setSelected: Dispatch<SetStateAction<number[]>>
}
const FollowUpViewTableBlock = ({ followUpView, loading, selected, setSelected }: Props) => {
  const initialOrder = (storageGet('accounts_order') || 'desc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('accounts_order_by') || 'DueDateTime') as keyof UspFollowUpViewResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspFollowUpViewResult>(initialOrderBy)
  const navigate = useNavigate()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const paginatedData = followUpView

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleSelect = (id: number | null) => {
    if (id === null) return

    const newSelected = selected.includes(id) ? selected.filter((item) => item !== id) : [...selected, id]

    setSelected(newSelected)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspFollowUpViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('accounts_order', isAsc ? 'desc' : 'asc')
    storageSet('accounts_order_by', property)
  }

  const createSortHandler = (property: keyof UspFollowUpViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = followUpView?.map((item) => item.DealID) as number[]
      setSelected(newSelected)
      return
    }
    setSelected([])
  }
  return (
    <>
      <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  indeterminate={selected?.length > 0 && selected?.length < followUpView?.length}
                  checked={followUpView?.length > 0 && selected?.length === followUpView?.length}
                  onChange={handleSelectAll}
                />
              </TableCell>

              <TableCell sortDirection={orderBy === 'Name' ? order : false}>
                <TableSortLabel active={orderBy === 'Name'} direction={orderBy === 'Name' ? order : 'asc'} onClick={createSortHandler('Name')}>
                  Name
                  {orderBy === 'Name' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'Title' ? order : false}>
                <TableSortLabel active={orderBy === 'Title'} direction={orderBy === 'Title' ? order : 'asc'} onClick={createSortHandler('Title')}>
                  Title
                  {orderBy === 'Title' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'AssignedTo' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'AssignedTo'}
                  direction={orderBy === 'AssignedTo' ? order : 'asc'}
                  onClick={createSortHandler('AssignedTo')}
                >
                  Assigned To
                  {orderBy === 'AssignedTo' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'Status' ? order : false}>
                <TableSortLabel active={orderBy === 'Status'} direction={orderBy === 'Status' ? order : 'asc'} onClick={createSortHandler('Status')}>
                  Status
                  {orderBy === 'Status' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'DueDateTime' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'DueDateTime'}
                  direction={orderBy === 'DueDateTime' ? order : 'asc'}
                  onClick={createSortHandler('DueDateTime')}
                >
                  Due
                  {orderBy === 'DueDateTime' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'DataSource' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'DataSource'}
                  direction={orderBy === 'DataSource' ? order : 'asc'}
                  onClick={createSortHandler('DataSource')}
                >
                  Data Source
                  {orderBy === 'DataSource' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), paginatedData)
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item) => (
                <TableRow hover key={item.FollowUpID} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                  <TableCell padding='checkbox'>
                    <Checkbox checked={selected.includes(item.FollowUpID as number)} onChange={() => handleSelect(item.FollowUpID as number)} />
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      if (item.DataSource == 'Lead') {
                        navigate(appRoute.LeadsTask.replace(':leadID', item?.CreditAppID?.toString() as string))
                      } else if (item.DataSource == 'Deal') {
                        navigate(appRoute.DealsTask.replace(':dealID', item?.DealID?.toString() as string))
                      } else {
                        navigate(appRoute.AccountsOverview.replace(':dealID', item?.DealID?.toString() as string))
                      }
                    }}
                  >
                    {item.Name}
                    <IconButton
                      color='primary'
                      sx={{ margin: 0, padding: '0 0 0 10px' }}
                      onClick={(event: any) => {
                        event?.stopPropagation() // This stops the event from bubbling up
                        if (item.DataSource == 'Lead') {
                          window.open(
                            appRoute.LeadsTask.replace(':leadID', item?.CreditAppID?.toString() as string),
                            '_blank', // <- This is what makes it open in a new window.
                          )
                        } else if (item.DataSource == 'Deal') {
                          window.open(
                            appRoute.DealsTask.replace(':dealID', item?.DealID?.toString() as string),
                            '_blank', // <- This is what makes it open in a new window.
                          )
                        } else {
                          window.open(
                            appRoute.AccountsOverview.replace(':dealID', item?.DealID?.toString() as string),
                            '_blank', // <- This is what makes it open in a new window.
                          )
                        }
                      }}
                    >
                      <OpenInNewIcon sx={{ width: '15px', height: '15px' }} />
                    </IconButton>
                  </TableCell>
                  <TableCell>{item?.Title}</TableCell>
                  <TableCell>{item?.AssignedTo}</TableCell>
                  <TableCell>{item?.Status}</TableCell>
                  <TableCell
                    sx={{
                      color: item.IsPastDue ? 'red' : '',
                    }}
                  >
                    {convertDateTimeToDisplay(item?.DueDateTime)}
                  </TableCell>
                  <TableCell>{item?.DataSource}</TableCell>
                </TableRow>
              ))}
            {followUpView && followUpView?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
        <Paper>
          <TablePagination
            component='div'
            count={followUpView?.length as number}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  )
}

export default FollowUpViewTableBlock
