import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid, Button, Popover } from '@mui/material'
import { ReactComponent as CircleIcon } from '../../../assets/icons/circle.svg'
import { generateUtilityClasses } from '@mui/material'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import {
  AccountsDropdownResult,
  AccountsOverviewResult,
  AddressData,
  UspCollectionWorklistGetNextAccountResult,
  UspContactPhoneData,
  UspDealFlagViewResult,
} from '../../../models'
import _ from 'lodash'
import HeaderDetailsModal from './components/HeaderDetailsModal/HeaderDetailsModal'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { applyPhoneMask, fetchCurrentDateTimeFromServer, getCurrentDate } from '../../../utils/general'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddNoteModal from './components/AddNoteModal/AddNoteModal'
import { useDispatch, useSelector } from 'react-redux'
import DealsCommissionsAddEdit from '../../Deals/DealsCommissions/components/DealsCommissionsAddEdit/DealsCommissionsAddEdit'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../constants'
import AddPaymentModal from './components/AddPaymentModal/AddPaymentModal'
import AddSideNoteModal from './components/AddSideNoteModal/AddSideNoteModal'
import {
  useAccountsChargeOffMutation,
  useCollectionWorklistMutation,
  useGetTimeMutation,
  useLazyGetAccountOverviewQuery,
  useModifyDealFinalizeUnwindSalesMutation,
} from '../../../services'
import { setWorklistAccountID, setWorklistID } from '../../../redux/slices/collectionsSlice'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import AddEditFlagModal from '../AccountsOverview/components/AddEditFlagModal/AddEditFlagModal'
import CenteredModal from '../../../components/Modal/CenteredModal'
import ChargeOffModal from '../AccountsRepo/components/ChargeOffModal/ChargeOffModal'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { getRepoChargeOff, getRepoReverseChargeOff, setRepoChargeOff } from '../../../redux/slices/accountsSlice'
import { getAccounts as getAccountsSelector, setRepoReverseChargeOff, setAccounts } from '../../../redux/slices/accountsSlice'
import AddExtensionModal from './components/AddExtensionModal/AddExtensionModal'
import { convertDateToString } from '../../../utils/dateUtils'
const classes = generateUtilityClasses('Container', [
  'root',
  'iconSeparator',
  'headerWrap',
  'eyeIcon',
  'columns',
  'firstItem',
  'secondItem',
  'tabLinkWrap',
  'tabLink',
])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '60px',
    backgroundColor: theme.palette.background.main,
    maxWidth: 'unset',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.eyeIcon}`]: {
    fill: theme.palette.primary.main,
    width: '20px',
  },
  [`& .${classes.iconSeparator}`]: {
    margin: theme.spacing(0, 1, 0, 1),
    fill: theme.palette.primary.main,
  },
  [`& .${classes.headerWrap}`]: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '11px',
  },

  [`& .${classes.columns}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.firstItem}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  [`& .${classes.secondItem}`]: {
    marginLeft: '30px',
  },
  [`& .${classes.tabLinkWrap}`]: {
    display: 'flex',
    height: '60px',
  },
  [`& .${classes.tabLink}`]: {
    textDecoration: 'none',
    height: '60px',
    minHeight: '60px',
    padding: theme.spacing(0, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    fontSize: '1em',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.background.hoverBtn,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.active,
    },
  },
}))

interface Props {
  accountsData?: any
  accountsIsSuccess?: any
  disableEditIcons?: boolean
  model?: string | null
  vin?: string | null
  stockNumber?: string | null
  buyerName?: string | null
  coBuyerName?: string | null
  buyerPhone?: UspContactPhoneData[]
  buyerWorkPhone?: any
  coBuyerWorkPhone?: any
  coBuyerPhone?: UspContactPhoneData[]
  buyerAddress?: AddressData[]
  coBuyerAddress?: AddressData[]
  contactID1?: number | null
  contactID2?: number | null
  handleTabChange?: (event: React.SyntheticEvent, newValue: 'applicant' | 'employment') => void
  tabValue?: 'applicant' | 'employment'
  errorsInTabs?: { applicant: boolean; employment: boolean }
  activeTab?: string
  getDealCommission?: (e: any) => void
  getAccountNotesHistory?: (e: any) => void
  getAccountDueAmount?: (e: any) => void
  includeDeletedNotes?: boolean
  getAccountsOverview?: any
  getAccountsPayHistory?: any
  branchID?: any
  carID?: any
  isAccountActive?: boolean
  isDeficiency?: boolean
  worklistAccountID?: number
  worklistID?: number
  nextAccountData?: UspCollectionWorklistGetNextAccountResult
  nextAccountIsSuccess?: boolean
  handleNextAccount?: () => void
  handlePreviousAccount?: () => void
  isAddEditFlagModalOpen?: boolean
  editAccountFlags?: (e: any) => void
  addAccountFlags?: (e: any) => void
  selectedFlag?: UspDealFlagViewResult | undefined
  setSelectedFlag?: Dispatch<SetStateAction<UspDealFlagViewResult | undefined>>
  setIsAddEditFlagModalOpen?: Dispatch<SetStateAction<boolean>>
}

const Header = ({
  disableEditIcons,
  model,
  vin,
  stockNumber,
  buyerName,
  buyerPhone,
  buyerWorkPhone,
  coBuyerWorkPhone,
  buyerAddress,
  coBuyerName,
  coBuyerPhone,
  coBuyerAddress,
  contactID1,
  contactID2,
  handleTabChange,
  tabValue,
  errorsInTabs,
  activeTab,
  getDealCommission,
  getAccountNotesHistory,
  getAccountDueAmount,
  includeDeletedNotes,
  getAccountsOverview,
  getAccountsPayHistory,
  branchID,
  carID,
  isAccountActive,
  isDeficiency,
  worklistAccountID,
  worklistID,
  nextAccountData,
  nextAccountIsSuccess,
  handleNextAccount,
  handlePreviousAccount,
  addAccountFlags,
  selectedFlag,
  setIsAddEditFlagModalOpen,
  isAddEditFlagModalOpen,
  editAccountFlags,
  setSelectedFlag,
  accountsData,
  accountsIsSuccess,
}: Props) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { dealID } = useParams()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [anchorElCommission, setAnchorElCommission] = useState<HTMLButtonElement | null>(null)
  const [anchorElAdd, setAnchorElAdd] = React.useState<HTMLButtonElement | null>(null)
  const [anchorElWorklist, setAnchorElWorklist] = React.useState<HTMLButtonElement | null>(null)
  const [buyerData, setBuyerData] = useState<any>(null)
  const [coBuyerData, setCoBuyerData] = useState<any>(null)
  const [activeData, setActiveData] = useState<'buyer' | 'coBuyer' | null>(null) // Track active data
  const [addNoteModalOpen, setAddNoteModalOpen] = React.useState(false)
  const [openPaymentModal, setOpenPaymentModal] = React.useState(false)
  const [openSideNoteModal, setOpenSideNoteModal] = React.useState(false)
  const [openExtensionModal, setOpenExtensionModal] = React.useState(false)
  const [getCollections] = useCollectionWorklistMutation()
  const userAccess = useSelector(getUserAccessRights)
  const open = Boolean(anchorElAdd)
  const openWorklist = Boolean(anchorElWorklist)
  const [dealFinalizeUnwindSales, { data: dealFinalizeUnwindSalesData, isSuccess: dealFinalizeUnwindSalesIsSuccess }] =
    useModifyDealFinalizeUnwindSalesMutation()
  const repoChargeOffTrigger = useSelector(getRepoChargeOff)
  const repoReverseChargeOffTrigger = useSelector(getRepoReverseChargeOff)
  const accounts: AccountsOverviewResult = useSelector(getAccountsSelector(dealID))
  const [getTime] = useGetTimeMutation()
  const [editChargeOff, { isSuccess: editChargeOffSuccess }] = useAccountsChargeOffMutation()

  const id = open ? 'add-menu-popover' : undefined
  const handleFinilize = () => {
    setAnchorEl(null)
    dealFinalizeUnwindSales({
      dealId: dealID as string,
      uspDealFinalizeUnwindSales: {
        isUnwind: true,
        isRefinance: false,
        isSystemGenerated: false,
      },
    })
  }

  useEffect(() => {
    if (dealFinalizeUnwindSalesIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))

      navigate(appRoute.Accounts)
    }
  }, [dealFinalizeUnwindSalesData, dealFinalizeUnwindSalesIsSuccess])

  useEffect(() => {
    if (nextAccountIsSuccess && nextAccountData) {
      dispatch(setWorklistAccountID(nextAccountData?.WorklistAccountID))
      dispatch(setWorklistID(nextAccountData?.WorklistID))
    }
  }, [nextAccountData, nextAccountIsSuccess])

  const handleOpenAddMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAdd(event.currentTarget)
  }

  const handleOpenWorklistMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElWorklist(event.currentTarget)
  }

  const handleCloseAddMenu = () => {
    setAnchorElAdd(null)
  }

  const handleCloseWorklistMenu = () => {
    setAnchorElWorklist(null)
  }

  const handleOpenPaymentModal = () => setOpenPaymentModal(true)
  const handleClosePaymentModal = () => setOpenPaymentModal(false)

  useEffect(() => {}, [activeTab])

  useEffect(() => {
    if (accountsIsSuccess) {
      dispatch(setAccounts(accountsData))
    }
  }, [accountsIsSuccess, accountsData, dispatch, setRepoReverseChargeOff])

  useEffect(() => {
    if (nextAccountIsSuccess && nextAccountData) {
      if (Number(nextAccountData?.DealID) === Number(dealID)) {
        dispatch(enqueueNotification({ message: 'No accounts found.', options: { variant: 'warning' } }))
      }
      if (nextAccountData?.DealID !== null || Number(nextAccountData?.DealID) !== Number(dealID)) {
        dispatch(enqueueNotification({ message: 'Success.' }))
        navigate(appRoute.AccountsOverview.replace(':dealID', nextAccountData?.DealID?.toString()))
      } else {
        dispatch(enqueueNotification({ message: 'Account not found.', options: { variant: 'warning' } }))
      }
    }
  }, [nextAccountData, nextAccountIsSuccess])

  useEffect(() => {
    fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
      getCollections({
        uspCollectionWorklistCommon: {
          effectiveDate: convertDateToString(currentDate as string) ?? '',
        },
      })
    })
  }, [])

  useEffect(() => {
    setBuyerData({
      ContactID1: contactID1,
      BuyerName: buyerName,
      BuyerAddressData: _.first(buyerAddress)?.Address1,
      BuyerAddressData2: _.first(buyerAddress)?.Address2,
      BuyerCity: _.first(buyerAddress)?.City,
      BuyerState: _.first(buyerAddress)?.State,
      BuyerPostalCode: _.first(buyerAddress)?.PostalCode,
      BuyerPhoneData: buyerPhone,
      BuyerWorkPhone: buyerWorkPhone,
    })
  }, [contactID1, buyerName, buyerAddress, buyerPhone, buyerWorkPhone])

  useEffect(() => {
    setCoBuyerData({
      ContactID2: contactID2,
      CoBuyerName: coBuyerName,
      CoBuyerAddressData: _.first(coBuyerAddress)?.Address1,
      CoBuyerAddressData2: _.first(coBuyerAddress)?.Address2,
      CoBuyerCity: _.first(coBuyerAddress)?.City,
      CoBuyerState: _.first(coBuyerAddress)?.State,
      CoBuyerPostalCode: _.first(coBuyerAddress)?.PostalCode,
      CoBuyerPhoneData: coBuyerPhone,
      CoBuyerWorkPhone: coBuyerWorkPhone,
    })
  }, [contactID2, coBuyerName, coBuyerAddress, coBuyerPhone, coBuyerWorkPhone])

  const handleAddNoteModalOpen = () => {
    setAddNoteModalOpen(true)
  }

  const handleAddNoteModalClose = () => {
    setAddNoteModalOpen(false)
  }

  const handleAddSideNoteModalOpen = () => {
    setOpenSideNoteModal(true)
  }

  const handleAddSideNoteModalClose = () => {
    setOpenSideNoteModal(false)
  }

  const handleAddCommissionModalOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElCommission(event?.currentTarget)
  }

  const handleOpenAddEditFlagModal = () => {
    setIsAddEditFlagModalOpen && setIsAddEditFlagModalOpen(true)
  }

  const onSuccessCommissionDeal = () => {
    if (activeTab === 'commissions') {
      getDealCommission && getDealCommission({ dealId: dealID as string })
    }
    dispatch(enqueueNotification({ message: 'Commission added successfully.' }))
  }

  const handleChargeOff = () => {
    if (dealID && accounts?.IsDeficiency) {
      editChargeOff({
        dealId: dealID as string,
        accountsChargeOff: {
          dealID: dealID,
          isReversal: true,
        },
      })
      dispatch(setRepoReverseChargeOff(undefined))
    }
  }

  const onSuccessEditChargeOff = () => {
    dispatch(enqueueNotification({ message: 'Success.' }))
    getAccountsOverview({ dealId: dealID as string })
  }
  const handleChargeOffModal = (item?: any) => {
    dispatch(setRepoChargeOff(true))

    setAnchorEl(null)
  }

  const handleReverseChargeOffModal = (item?: any) => {
    dispatch(setRepoReverseChargeOff(true))

    setAnchorEl(null)
  }

  return (
    <>
      {worklistID && (
        <Button
          sx={{ position: 'absolute', right: 150, top: 0, zIndex: 1 }}
          variant='outlined'
          onClick={handleOpenWorklistMenu}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Worklist
        </Button>
      )}
      <Popover
        id={id}
        open={openWorklist}
        anchorEl={anchorElWorklist}
        onClose={handleCloseWorklistMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid item style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
          <Button
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={() => {
              handleNextAccount && handleNextAccount()
              handleCloseWorklistMenu()
            }}
          >
            Next Account
          </Button>
          <Button
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={() => {
              handlePreviousAccount && handlePreviousAccount()
              handleCloseWorklistMenu()
            }}
          >
            Previous Account
          </Button>
          <Button
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={() => {
              navigate(appRoute.CollectionsMyWorklist)
            }}
          >
            Go to worklist
          </Button>
        </Grid>
      </Popover>
      <Button
        sx={{ position: 'absolute', right: 30, top: 0, zIndex: 1 }}
        variant='outlined'
        onClick={handleOpenAddMenu}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElAdd}
        onClose={handleCloseAddMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid item style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
          {/* <Button
            disabled={!userAccess?.CanAddPayment}
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={() => {
              handleOpenAddEditFlagModal()
              handleCloseAddMenu()
            }}
          >
            Flag
          </Button> */}
          {accountsData?.IsAccountActive == true || accountsData?.IsDeficiency == true ? (
            <Button
              disabled={!userAccess?.CanAddPayment}
              style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
              onClick={() => {
                handleOpenPaymentModal()
                handleCloseAddMenu()
              }}
            >
              Add Payment
            </Button>
          ) : (
            <></>
          )}
          {(isDeficiency || (isAccountActive && userAccess.CanAddSideNote)) && (
            <Button
              style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
              onClick={() => {
                handleAddSideNoteModalOpen()
                handleCloseAddMenu()
              }}
            >
              Add Side Note
            </Button>
          )}
          {accounts?.IsAccountActive && (accountsData?.DealType == 'Financed' || accountsData?.DealType == 'Lease') && (
            <Button
              style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
              onClick={() => {
                setOpenExtensionModal(true)
                handleCloseAddMenu()
              }}
            >
              Add Extension
            </Button>
          )}
          {accounts?.IsDeficiency === false && accounts?.IsAccountActive === true && (
            <Button
              style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
              onClick={() => {
                handleChargeOffModal()
                handleCloseAddMenu()
              }}
            >
              Charge Off
            </Button>
          )}
          <Button
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={() => {
              navigate(
                generatePath(appRoute.PrintForms, {
                  dealID,
                }),
              )
            }}
          >
            Print Forms
          </Button>
          {/* <Button
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={() => {
              handleAddNoteModalOpen()
              handleCloseAddMenu()
            }}
          >
            Note
          </Button> */}

          {accounts?.IsDeficiency === true && (
            <Button
              style={{ textAlign: 'left', justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
              onClick={() => {
                handleReverseChargeOffModal()
                handleCloseAddMenu()
              }}
            >
              Reverse Charge Off
            </Button>
          )}
          <Button
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={() => {
              handleFinilize()
              handleCloseAddMenu()
            }}
          >
            Unwind Deal
          </Button>

          {/* <Button
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={() => {
              navigate(
                appRoute.AccountsReferencesAddEdit.replace(':dealID', dealID as string)
                  .replace(':contactID', contactID1?.toString() as string)
                  .replace(':referenceID', 'add' as string),
              )
              handleCloseAddMenu()
            }}
          >
            Reference
          </Button>
          <Button
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={() => {
              navigate(appRoute.AccountsReferralEdit.replace(':dealID', dealID as string).replace(':referral', 'add'))
              handleCloseAddMenu()
            }}
          >
            Referral
          </Button>
          <Button
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={(e) => {
              handleAddCommissionModalOpen(e)
              handleCloseAddMenu()
            }}
          >
            Commission
          </Button> */}
          {/* <Button style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}>Document</Button> */}
        </Grid>
      </Popover>
      <HeaderDetailsModal
        disableEditIcons={disableEditIcons}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        buyerData={buyerData}
        coBuyerData={coBuyerData}
        activeData={activeData as any}
      />
      <AddNoteModal
        open={addNoteModalOpen}
        onClose={handleAddNoteModalClose}
        getAccountNotesHistory={getAccountNotesHistory as (e: any) => void}
        includeDeletedNotes={includeDeletedNotes as boolean}
        isAccountActive={isAccountActive}
      />
      <AddExtensionModal
        getAccountDueAmount={getAccountDueAmount}
        accountData={accountsData}
        open={openExtensionModal}
        getAccountsOverview={getAccountsOverview}
        onClose={() => setOpenExtensionModal(false)}
        isAccountActive={isAccountActive}
      />

      <DealsCommissionsAddEdit
        anchorEl={anchorElCommission}
        setAnchorEl={setAnchorElCommission}
        type='add'
        onSuccessCommissionDeal={onSuccessCommissionDeal}
      />
      <AddPaymentModal
        getAccountsPayHistory={getAccountsPayHistory}
        getAccountsOverview={getAccountsOverview}
        getAccountDueAmountRequest={getAccountDueAmount as (e: any) => void}
        openModal={openPaymentModal}
        handleClose={handleClosePaymentModal}
        branchID={branchID}
        carID={carID}
        contactID1={contactID1 as any}
      />
      <AddSideNoteModal
        getAccountsPayHistory={getAccountsPayHistory}
        includeDeletedNotes={includeDeletedNotes as boolean}
        getAccountNotesHistory={getAccountNotesHistory as (e: any) => void}
        getAccountsOverview={getAccountsOverview}
        open={openSideNoteModal}
        getAccountDueAmountRequest={getAccountDueAmount as (e: any) => void}
        onClose={handleAddSideNoteModalClose}
      />
      <CenteredModal
        open={isAddEditFlagModalOpen as boolean}
        setOpen={() => {
          setSelectedFlag && setSelectedFlag(undefined)
          setIsAddEditFlagModalOpen && setIsAddEditFlagModalOpen(false)
        }}
        sx={{
          minWidth: 150,
          width: 450,
        }}
      >
        <AddEditFlagModal
          setIsAddEditFlagModalOpen={setIsAddEditFlagModalOpen}
          editAccountFlags={editAccountFlags as (e: any) => void}
          selectedFlag={selectedFlag}
          isAddEditFlagModalOpen={isAddEditFlagModalOpen as boolean}
          addAccountFlags={addAccountFlags as (e: any) => void}
        />
      </CenteredModal>
      <ChargeOffModal
        openModal={repoChargeOffTrigger}
        deficiency={accounts?.IsDeficiency as boolean}
        editChargeOffSuccess={editChargeOffSuccess}
        onSuccessEditChargeOff={onSuccessEditChargeOff}
        editChargeOff={editChargeOff}
      />

      <ConfirmDialog
        open={!!repoReverseChargeOffTrigger}
        onOk={handleChargeOff as any}
        handleClose={() => dispatch(setRepoReverseChargeOff(undefined))}
        notification={'Do you want to reverse charge off?'}
      />
    </>
  )
}

export default Header
