import React, { useEffect, useMemo, useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { UspCarImageViewResult } from '../../../models'
import { Button, Divider, Grid, IconButton, Modal, Stack, Typography } from '@mui/material'
import Iconify from '../../../components/iconify/iconify'
import { styled } from '@mui/material/styles'

import ViewIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'
import { useDeleteCarImageMutation } from '../../../services'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { CustomCircular } from '../../../ui'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { useParams } from 'react-router-dom'

const HoverButtons = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
}))

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  width: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  textAlign: 'left',
  border: '1px solid transparent',
  '&:hover': {
    border: '1px solid white',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}))

const StyledImageListItem = styled('div')(({ theme }) => ({
  display: 'block',
  position: 'relative',
  paddingBottom: '100%',
  '& img': {
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
  },
}))

const StyledModal = styled('div')(({ theme }) => ({
  background: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80vw',
  height: '80vh',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(0),
  right: theme.spacing(0),
  color: theme.palette.grey[500],
  zIndex: 1301,
  padding: 0,
  margin: 0,
}))

const EnlargedImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})

const ImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  '&:hover': {
    '& .Overlay': {
      opacity: 0.5,
    },
    '& .HoverButtons': {
      display: 'flex',
      gap: theme.spacing(1),
      flexDirection: 'column',
      zIndex: 2,
    },
  },
}))

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  opacity: 0,
  transition: 'opacity 0.3s ease',
  borderRadius: '5px',
})

export function SortableItem({
  reorder,
  image,
  id,
  getCarImages,
}: {
  reorder: boolean
  image: UspCarImageViewResult
  id: number
  getCarImages: any
}) {
  const { carID } = useParams()

  const [selectedImage, setSelectedImage] = React.useState<string | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<any>(null)
  const [deleteCarImages, { isLoading: deleteCarImagesIsLoading, isSuccess: deleteCarImagesIsSuccess }] = useDeleteCarImageMutation()
  const dispatch = useDispatch()
  const [formChanged, setFormChanged] = useState(false)
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const handleDeleteImage = () => {
    setDeleteConfirmDialog(undefined)
    deleteCarImages({ carId: carID?.toString() as string, carImageId: deleteConfirmDialog })
  }
  const open = Boolean(anchorEl)
  const uniqieId = open ? 'image-popover' : undefined

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: image.CarImageID,

    animateLayoutChanges: () => false,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const isLoading = useMemo(() => deleteCarImagesIsLoading, [deleteCarImagesIsLoading])

  useEffect(() => {
    if (deleteCarImagesIsSuccess) {
      getCarImages({ carId: carID?.toString() as string })
      dispatch(enqueueNotification({ message: 'Success' }))
    }
  }, [deleteCarImagesIsSuccess])

  const handleViewClick = (event: React.MouseEvent<HTMLButtonElement>, imageLink: string) => {
    setAnchorEl(event.currentTarget)
    setSelectedImage(imageLink)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedImage(null)
  }

  return (
    <>
      {reorder ? (
        <Grid item xs={12} style={style} ref={setNodeRef} {...attributes} {...listeners} sm={5} md={5} lg={2} xl={2} key={image.CarImageID}>
          <ImageWrapper sx={{ cursor: 'pointer' }} key={image.CarImageID}>
            <StyledImageListItem>
              <img style={{ borderRadius: '10px' }} src={image.ImageLink} alt='Inventory Car Image' loading='lazy' />
            </StyledImageListItem>
          </ImageWrapper>
        </Grid>
      ) : (
        <Grid item xs={12} sm={5} md={5} lg={2} xl={2} key={image.CarImageID}>
          <ImageWrapper sx={{ cursor: 'pointer' }} key={image.CarImageID}>
            <StyledImageListItem>
              <img style={{ borderRadius: '10px' }} src={image.ImageLink} alt='Inventory Car Image' loading='lazy' />
              <Overlay className='Overlay' />
              <HoverButtons className='HoverButtons'>
                <StyledButton variant='outlined' onClick={(e: any) => handleViewClick(e, image?.ImageLink)}>
                  <ViewIcon />
                  View
                </StyledButton>
                <StyledButton variant='outlined' onClick={() => setDeleteConfirmDialog(image.CarImageID)}>
                  <DeleteIcon />
                  Delete
                </StyledButton>
              </HoverButtons>
            </StyledImageListItem>
          </ImageWrapper>
        </Grid>
      )}
      <Modal id={uniqieId} open={open} onClose={handleClose}>
        <StyledModal onClick={handleClose}>
          <CloseButton aria-label='close' onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          {selectedImage && <EnlargedImage src={selectedImage} alt='Enlarged' />}
        </StyledModal>
      </Modal>
      <CustomCircular open={isLoading} />
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDeleteImage}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Image will be removed'}
      />
    </>
  )
}
