import React, { useEffect } from 'react'
import { AuthTemplate } from '../../components/AuthTemplate'
import signInBg from '../../assets/signIn-bg.png'
import { Box, Button, IconButton, InputAdornment, Link, Link as MLink, Stack, Typography, useTheme } from '@mui/material'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { useLazyCompanyTypesQuery, useLoginCreateMutation, useUserLoggedInMutation } from '../../services'
import { enqueueNotification } from '../../redux/slices/notificationsSlice'
import { signInLabels, signInValidation } from '../../validation'
import { appRoute } from '../../constants'
import { useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import validateForm from '../../utils/validateForm'
import { TextField, PasswordField, CustomCircular } from '../../ui'
import { setCredentials, setSession, setAuthInProgress, setBranchData, setCompanyTypes } from '../../redux/slices/authSlice'
import { SignInFormType, ClientList } from '../../models'
import { LoadingButton } from '@mui/lab'
import { RHFTextField } from '../../components/hook-form'
import Iconify from '../../components/iconify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { RouterLink } from '../../routes/components'
import * as Yup from 'yup'
import { useBoolean } from '../../hooks/use-boolean'
import FormProvider from '../../components/hook-form/form-provider'

export const SignIn = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [login, { data: loginData, isSuccess: loginDataIsSuccess }] = useLoginCreateMutation()
  const [companyTypes, { data: companyTypesData, isSuccess: companyTypeIsSuccess, isLoading: companyTypeIsLoading }] = useLazyCompanyTypesQuery()
  const [userLogin, { isSuccess: userLoginIsSuccess }] = useUserLoggedInMutation()

  const password = useBoolean()

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  })

  const defaultValues = {
    email: '',
    password: '',
  }

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login({ loginToken: data })

      // await new Promise((resolve) => setTimeout(resolve, 500));
      console.info('DATA', data)
    } catch (error) {
      console.error(error)
    }
  })

  const handleErrorNoClient = () => {
    dispatch(
      enqueueNotification({
        message: 'Unexpected error, no clients to use',
        options: { variant: 'error' },
      }),
    )
  }

  useEffect(() => {
    if (loginDataIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      dispatch(setAuthInProgress(true))
      dispatch(setCredentials(loginData))
      const clientsListLength = loginData?.clients_list?.length || 0
      if (clientsListLength === 0) {
        // No clients
        handleErrorNoClient()
      } else if (clientsListLength === 1) {
        // One client
        const clientUid = Array.isArray(loginData?.clients_list) && loginData?.clients_list[0]?.client_uid
        if (clientUid) {
          companyTypes({ clientUid })
        } else {
          handleErrorNoClient()
        }
      } else {
        // Multiple clients
        // dispatch(enqueueNotification({ message: 'Error' }))
        dispatch(setAuthInProgress(false))
        navigate(appRoute.SelectSession, { replace: true })
      }
    }
  }, [loginDataIsSuccess])

  useEffect(() => {
    // Check if there is variants
    if (companyTypeIsSuccess) {
      if (companyTypesData?.length === 1) {
        const companyType: ClientList = companyTypesData[0]
        const branchDataLength = companyType?.BranchData?.length || 0

        if (branchDataLength > 1) {
          // if no variants
          dispatch(setAuthInProgress(false))
          navigate(appRoute.SelectSession, { replace: true })
        } else {
          const clientUid = Array.isArray(loginData?.clients_list) && loginData?.clients_list[0]?.client_uid
          userLogin({
            'CLIENT-UID': clientUid,
            'COMPANY-TYPE': companyType?.CompanyType,
            'COMPANY-BRANCH': companyType?.BranchID,
          })
        }
      } else {
        dispatch(setAuthInProgress(false))
        navigate(appRoute.SelectSession, { replace: true })
      }
    }
  }, [companyTypeIsSuccess])

  useEffect(() => {
    // Check if there is variants
    if (userLoginIsSuccess) {
      const clientUid = Array.isArray(loginData?.clients_list) && loginData?.clients_list[0]?.client_uid
      if (companyTypesData?.length === 1) {
        const companyType = companyTypesData[0] as ClientList
        dispatch(setCredentials(loginData))
        dispatch(setBranchData(companyType?.BranchData))
        dispatch(
          setSession({
            client: clientUid,
            companyType: companyType?.CompanyType,
            branch: companyType?.BranchID,
          }),
        )
        dispatch(setCompanyTypes(companyTypesData))

        dispatch(setAuthInProgress(false))
        navigate(appRoute.Dashboard, { replace: true })
      }
    }
  }, [userLoginIsSuccess])

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant='h4'>Sign in to Elev8</Typography>

      <Stack direction='row' spacing={0.5}>
        <Typography variant='body2'>Don’t have an account? Contact your administrator.</Typography>
      </Stack>
    </Stack>
  )

  const renderForm = (
    <Stack spacing={2.5}>
      <RHFTextField name='email' label='Email address' />

      <RHFTextField
        name='password'
        label='Password'
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={password.onToggle} edge='end'>
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Link component={RouterLink} href={appRoute.ResetPassword} variant='body2' color='inherit' underline='always' sx={{ alignSelf: 'flex-end' }}>
        Forgot password?
      </Link>

      <LoadingButton fullWidth color='inherit' size='large' type='submit' variant='contained' loading={isSubmitting}>
        Login
      </LoadingButton>
    </Stack>
  )

  return (
    <AuthTemplate
      backgroundImg={signInBg}
      // topRight=More{<Box sx={{ fontSize: '14px', color: theme.palette.text.secondary }}>Don’t have an account? Contact your administrator.</Box>}
    >
      {/* <Typography
        sx={{
          marginTop: 6,
          fontWeight: 500,
          fontSize: '40px',
          color: theme.palette.text.primary,
        }}
        textAlign='center'
      >
        Log In
      </Typography>
      <Typography sx={{ marginTop: 3, fontSize: '16px', color: theme.palette.text.secondary }} textAlign='center'>
        Log in to your account.
      </Typography>
      <Form onSubmit={onSubmit} validate={validateForm(signInValidation)}>
        {({ handleSubmit, submitting, invalid }: any) => (
          <form onSubmit={handleSubmit}>
            <TextField
              name='email'
              label={signInLabels.email}
              fullWidth
              autoFocus
              sx={{ marginTop: 6 }}
              InputProps={{
                sx: { borderRadius: '8px' },
                id: 'userEmail',
                autoComplete: 'email',
              }}
            />
            <PasswordField
              sx={{ marginTop: 3, marginBottom: 1 }}
              InputProps={{
                sx: { borderRadius: '8px' },
                id: 'userPassword',
                autoComplete: 'password',
              }}
              label={signInLabels.password}
              fullWidth
              name='password'
            />

            <Link  to='/reset-password' underline='none'>
              Forgot my password
            </Link>
            <Button
              type='submit'
              sx={{
                borderRadius: '8px',
                background: '#1570EF',
                marginTop: 5,
                paddingY: '12px',
              }}
              color='primary'
              variant='contained'
              fullWidth
              disabled={invalid || submitting}
            >
              {submitting ? <CircularProgress /> : 'Next'}
            </Button>
          </form>
        )}
      </Form> */}
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderHead}

        {renderForm}
      </FormProvider>
      <CustomCircular open={companyTypeIsLoading} />
    </AuthTemplate>
  )
}
