import React from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid, Button, Tooltip } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/printer.svg'
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download_icon.svg'
import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter_icon.svg'
import { UspReportInventoryListResult } from '../../../../../models'
import * as XLSX from 'xlsx'
import { parseToNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'btnWrap'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1, 3, 1, 3),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  [`& .${classes.btnWrap}`]: {
    maxWidth: '30px',
    maxHeight: '42px',
    minWidth: '30px',
    minHeight: '42px',
    padding: '3px',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },
}))

interface Props {
  handleOpenFilterModal: () => void
  filterData: UspReportInventoryListResult[]
}

export const Header = ({ handleOpenFilterModal, filterData }: Props) => {
  const transformDataForExport = (data: UspReportInventoryListResult[]) => {
    return data?.map((item: UspReportInventoryListResult) => ({
      BV2: item.BV2,
      BV1: item.BV1,
      BodyType: item.BodyType,
      Branch: item.Branch,
      CarID: item.CarID,
      CashDown: item.CashDown,
      Color: item.Color,
      Condition: item.Condition,
      Cylinders: item.Cylinders,
      DaysAvailable: item.DaysAvailable,
      DepositAmount: item.DepositAmount,
      Description: item.Description,
      Engine: item.Engine,
      Fuel: item.Fuel,
      Mileage: item.Mileage,
      PurchaseType: item.PurchaseType,
      Status: item.Status,
      StickerPrice: item.StickerPrice,
      StockNumber: item.StockNumber,
      Transmission: item.Transmission,
      Trim: item.Trim,
      VIN: item.VIN,
    }))
  }

  const setColumnWidths = (worksheet: any) => {
    const columnWidths = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ]

    worksheet['!cols'] = columnWidths
  }

  const exportToExcel = (data: any, fileName: any) => {
    const transformedData = transformDataForExport(data)
    const worksheet = XLSX.utils.json_to_sheet(transformedData)
    setColumnWidths(worksheet)

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, `${fileName}.xlsx`)
  }

  return (
    <>
      <Grid container justifyContent={'flex-end'} p={3} alignItems={'center'}>
        <Grid item>
          <Tooltip title='Download'>
            <Button variant='text' className={classes.btnWrap} onClick={() => exportToExcel(filterData, 'Inventory List')}>
              <DownloadIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Print'>
            <Button variant='text' className={classes.btnWrap}>
              <PrintIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Filter'>
            <Button onClick={handleOpenFilterModal} variant='text' className={classes.btnWrap}>
              <FilterIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  )
}

export default Header
