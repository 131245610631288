import React, { useEffect } from 'react'
import { CollectionsWorklistCommonApiResponse, UspCollectionWorklistViewResult } from '../../../../models'
import {
  Box,
  Grid,
  generateUtilityClasses,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { ReactComponent as LoginVector } from '../../../../assets/icons/login_vector.svg'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { storageGet, storageSet } from '../../../../utils/storage'
import { getComparator, stableSort } from '../../../../utils/sorting'
import { useCollectionWorklistMutation, useGetTimeMutation, useWorklistLoginMutation } from '../../../../services'
import { fetchCurrentDateTimeFromServer, getCurrentDate } from '../../../../utils/general'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../../constants'
import { setWorklistName } from '../../../../redux/slices/collectionsSlice'
import Iconify from '../../../../components/iconify'
import { convertDateToString } from '../../../../utils/dateUtils'

const classes = generateUtilityClasses('Grid', ['root', 'loading', 'tableHead'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },

  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },

  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
}))

interface Props {
  loading: boolean
  collectionWorklistData: CollectionsWorklistCommonApiResponse
}

const CollectionsLoginTableBlock = ({ loading, collectionWorklistData }: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const initialOrder = (storageGet('inventory_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('inventory_order_by') || 'WorklistName') as keyof UspCollectionWorklistViewResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspCollectionWorklistViewResult>(initialOrderBy)
  const [login, { isSuccess }] = useWorklistLoginMutation()
  const [getCollections, { data, isSuccess: isSuccessCollectionWorklist }] = useCollectionWorklistMutation()
  const [getTime] = useGetTimeMutation()

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspCollectionWorklistViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('inventory_order', isAsc ? 'desc' : 'asc')
    storageSet('inventory_order_by', property)
  }

  const createSortHandler = (property: keyof UspCollectionWorklistViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleLogin = (item: UspCollectionWorklistViewResult) => {
    dispatch(setWorklistName(item?.WorklistName))
    fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
      login({
        worklistId: String(item.WorklistID),
        uspCollectionWorklistCommon: { effectiveDate: convertDateToString(currentDate as string) ?? '' },
      })
    })
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
        getCollections({
          uspCollectionWorklistCommon: {
            effectiveDate: convertDateToString(currentDate as string) ?? '',
          },
        })
      })
    }
  }, [isSuccess])

  useEffect(() => {
    if (isSuccessCollectionWorklist) {
      if (data?.length === 0) {
        dispatch(enqueueNotification({ message: 'No accounts found', options: { variant: 'warning' } }))
      } else {
        navigate(appRoute.CollectionsMyWorklist)
      }
    }
  }, [isSuccessCollectionWorklist, data])

  return (
    <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 118px)' }}>
      <Table aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'WorklistName' ? order : false}>
              <TableSortLabel
                active={orderBy === 'WorklistName'}
                direction={orderBy === 'WorklistName' ? order : 'asc'}
                onClick={createSortHandler('WorklistName')}
              >
                Name
                {orderBy === 'WorklistName' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CreatedBy' ? order : false}>
              <TableSortLabel
                active={orderBy === 'CreatedBy'}
                direction={orderBy === 'CreatedBy' ? order : 'asc'}
                onClick={createSortHandler('CreatedBy')}
              >
                Created By
                {orderBy === 'CreatedBy' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Count_Accounts' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Count_Accounts'}
                direction={orderBy === 'Count_Accounts' ? order : 'asc'}
                onClick={createSortHandler('Count_Accounts')}
              >
                Accounts
                {orderBy === 'Count_Accounts' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Count_Unworked' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Count_Unworked'}
                direction={orderBy === 'Count_Unworked' ? order : 'asc'}
                onClick={createSortHandler('Count_Unworked')}
              >
                Unworked
                {orderBy === 'Count_Unworked' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} align={'right'}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), collectionWorklistData as any[]).map((item) => (
            <TableRow key={item.WorklistID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{item.WorklistName}</TableCell>
              <TableCell>{item.CreatedBy}</TableCell>
              <TableCell>{item.Count_Accounts}</TableCell>
              <TableCell>{item.Count_Unworked}</TableCell>
              <TableCell align={'right'}>
                <Tooltip title='Log In' onClick={() => handleLogin(item as UspCollectionWorklistViewResult)}>
                  <IconButton color='primary'>
                    <Iconify icon={'ic:outline-login'} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
          {collectionWorklistData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {loading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CollectionsLoginTableBlock
