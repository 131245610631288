import React, { useEffect } from 'react'
import Header from './component/Header'
import { useCollectionWorklistCommonMutation, useGetTimeMutation } from '../../../services'
import { fetchCurrentDateTimeFromServer, getCurrentDate } from '../../../utils/general'
import CollectionsLoginTableBlock from './component/CollectionsLoginTableBlock'
import { CollectionsWorklistCommonApiResponse } from '../../../models'
import { Card, Container } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { convertDateToString } from '../../../utils/dateUtils'

const CollectionsLoginWorklist = () => {
  const [getCollectionWorklist, { data, isLoading }] = useCollectionWorklistCommonMutation()
  const [getTime] = useGetTimeMutation()

  useEffect(() => {
    fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
      getCollectionWorklist({
        uspCollectionWorklistCommon: {
          effectiveDate: convertDateToString(currentDate as string) ?? '',
        },
      })
    })
  }, [])

  return (
    <Container>
      <CustomBreadcrumbs
        heading={`Worklists`}
        links={[
          {
            name: 'List',
          },
        ]}
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />

      <Card
        sx={{
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
          py: 2,
        }}
      >
        <CollectionsLoginTableBlock loading={isLoading} collectionWorklistData={data as CollectionsWorklistCommonApiResponse} />
      </Card>
    </Container>
  )
}

export default CollectionsLoginWorklist
