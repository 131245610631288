import { convertStringToDate } from '../../../utils/dateUtils'
import { getCurrentDate } from '../../../utils/general'
import removeMask from '../../../utils/removeMask'

export const transformToFormValues = () => ({
  FilterBy: 'PastDue',
  Amount: '0,00',
  IsPersonalWorklist: true,
  LoginWorklist: true,
  IncludePickupPayments: true,
  StrictlyPickupPayments: false,
  IncludeLender: false,
  IncludeAccounts: false,
  IncludeOutRepo: false,
  StrictlyOut: false,
  PastDue: 'Greater Than',
  OrderBy: 'DPD',
})

export const transformToRequestValues = (values: any,currentDate:string) => {
  const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID })) || []
  const jsonAccountStatus = values?.AccountStatus?.map((item: any) => ({ AccountStatus: item?.AccountStatus })) || []
  return {
    effectiveDate: currentDate,
    filterBy: values.FilterBy,
    startDaysPastDue: values.StartingDaysPastDue || null,
    endDaysPastDue: values.EndingDaysPastDue || null,
    startDate: values.FilterBy !== 'PastDue' ? convertStringToDate(values.startDate) : null,
    endDate: values.FilterBy !== 'PastDue' ? convertStringToDate(values.endDate) : null,
    startingPastDueAmount: removeMask(values.Amount) as string,
    jsonBranchList: jsonBranchList || [],
    jsonAccountStatusList: jsonAccountStatus || [],
    pastDueFilter: values.PastDue,
    isPersonalWorklist: values.IsPersonalWorklist,
    worklistName: !values.IsPersonalWorklist ? values.WorklistName : null,
    isLoginToWorklist: values.IsPersonalWorklist ? values.LoginWorklist : true,
    orderBy: values.OrderBy,
    isIncludePickups: values.IncludePickupPayments,
    isPickupOnly: values.IncludePickupPayments ? values.StrictlyPickupPayments : false,
    isIncludeLenderServicedDeals: values.IncludeLender,
    isIncludeAccountsWithFuturePromises: values.IncludeAccounts,
    isIncludeOutForRepo: values.IncludeOutRepo,
    isOutForRepoOnly: values.IncludeOutRepo ? values.StrictlyOut : false,
  }
}
