import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2'
import LoadingButton from '@mui/lab/LoadingButton'
import LaunchIcon from '@mui/icons-material/Launch'
import FormProvider from '../../../../components/hook-form/form-provider'
import { Button, CardHeader, Dialog, Divider, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import Iconify from '../../../../components/iconify'
import BuyerItem from '../../../Leads/LeadsList/components/buyer-item'
import Label from '../../../../components/label/label'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { Icon } from '@iconify/react'
import StipulationItem from '../../../Leads/LeadsList/components/stipulations-item'
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useCallback, useMemo, useState } from 'react'
import SettingsForm from './SettingsForm'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../constants'
import CustomPopover from '../../../../components/custom-popover/custom-popover'
import { usePopover } from '../../../../components/custom-popover'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import {
  selectDropdownByNameActiveOnly,
  useDeleteCoBuyerMutation,
  useDeleteCreditAppCoAplicantMutation,
  useDeleteCreditAppStipulationMutation,
  useDeleteDealCommissionMutation,
  useDeleteDealReferralMutation,
  useDeleteDealTradeMutation,
  useDeleteReferencesMutation,
} from '../../../../services'
import { CreditAppReference } from '../../../../models/leadTypes'
import AddEditStipulationsFormModal from '../../../Leads/LeadsStipulations/components/AddEditStipulationsFormModal'
import PullCreditModal from '../../../Leads/LeadsTask/components/Modals/PullCreditModal'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import CommissionItem from './commission-item'
import DealsCommissionsAddEdit from '../../DealsCommissions/components/DealsCommissionsAddEdit/DealsCommissionsAddEdit'
import { convertDateToDisplay } from '../../../../utils/dateUtils'
import ReferralItem from './referral-item'
import CenteredModal from '../../../../components/Modal/CenteredModal'
import DealsProductsEdit from '../../DealsProducts/components/DealsProductsEdit/DealsProductsEdit'
export default function DealGeneral({ data }: any) {
  const methods = useForm({})
  const [openSettingsModal, setOpenSettingsModal] = useState(false)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false)
  const [productEdit, setProductEdit] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<any>()
  const [tradeDeleteConfirmDialog, setTradeDeleteConfirmDialog] = useState(false)
  const [referenceDeleteConfirmDialog, setReferenceDeleteConfirmDialog] = useState(false)
  const [addNewModalOpen, setAddNewModalOpen] = useState(false)
  const [vin, setVin] = useState('')
  const [commissionDeleteModal, setCommissionDeleteModal] = useState(false)
  const [referralDeleteModal, setReferralDeleteModal] = useState(false)
  const [seletedReference, setSelectedReference] = useState<any>()
  const [selectedTrade, setSelectedTrade] = useState<any>()
  const [selectedCommission, setSelectedCommission] = useState<any>()
  const [selectedReferral, setSelectedReferral] = useState<any>()
  const [isPullCreditModalOpen, setIsPullCreditModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | EventTarget | HTMLLIElement | null>(null)
  const [userSelected, setUserSelected] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { dealID } = useParams()
  const [deleteCoBuyer, { isSuccess: isCoBuyerDeleted }] = useDeleteCoBuyerMutation()
  const [deleteReferences, { isSuccess: deleteReferencesIsSuccess }] = useDeleteReferencesMutation()
  const [deleteTrades, { isSuccess: deleteTradeIsSuccess }] = useDeleteDealTradeMutation()
  const [deleteDealReferal, { isSuccess }] = useDeleteDealReferralMutation()
  const [deleteCommissions, { isSuccess: deleteCommissionsIsSuccess }] = useDeleteDealCommissionMutation()
  const popover = usePopover()
  const referencePopover = usePopover()
  const tradePopover = usePopover()
  const productPopover = usePopover()
  const comissionPopover = usePopover()
  const referralPopover = usePopover()
  const INTEGRATION_VENDOR = '700Credit'
  const leadLostReasonDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Integrations'))
  const integrationVendor = leadLostReasonDropdown?.find(({ IntegrationVendor }) => IntegrationVendor === INTEGRATION_VENDOR)
  const bureausData = useMemo(() => (integrationVendor?.IntegrationData ? JSON.parse(integrationVendor?.IntegrationData) : []), [integrationVendor])
  console.log(leadLostReasonDropdown)
  const handleDelete = () => {
    if (data?.ContactID2 && dealID) {
      deleteCoBuyer({
        dealId: dealID,
        coBuyerId: data?.ContactID2,
      })
    }

    setDeleteConfirmDialog(false)
  }

  const handleComissionDelete = () => {
    if (selectedCommission?.CommissionID) {
      deleteCommissions({ dealId: dealID as string, commissionId: selectedCommission?.CommissionID.toString() })
      setCommissionDeleteModal(false)
    }
  }

  const handleReferenceDelete = () => {
    if (seletedReference) {
      deleteReferences({
        referenceId: seletedReference.ReferenceID.toString(),
      })
      setReferenceDeleteConfirmDialog(false)
    }
  }

  const handleTradeDelete = () => {
    if (selectedTrade) {
      deleteTrades({
        tradeId: selectedTrade.TradeID.toString(),
      })
      setTradeDeleteConfirmDialog(false)
    }
  }

  const handleReferralDelete = () => {
    if (selectedReferral) {
      setReferralDeleteModal(false)
      deleteDealReferal({
        referralId: selectedReferral?.ReferralID,
        dealId: dealID as string,
      })
    }
  }
  const onSuccessCommissionDeal = () => {
    dispatch(enqueueNotification({ message: 'Commission added successfully.' }))
  }

  const onSuccessEditCommissionDeal = () => {
    dispatch(enqueueNotification({ message: 'Commission edited successfully.' }))
  }

  const handleAddNew = useCallback(() => {
    if (!!vin.length) {
      navigate(
        generatePath(appRoute.DealTradeAddVin, {
          dealID,
          vin,
        }),
      )
      // navigate(
      // appRoute.DealsTradeEdit.replace(':dealID', dealID as string)
      //   .replace(':tradeID', selectedTrade.TradeID?.toString() || 'add')
      //   .replace(':vin', vin),
      // )
    } else {
      navigate(
        generatePath(appRoute.DealsTradeAdd, {
          dealID,
        }),
      )
    }
  }, [vin, navigate])

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500))
      console.info('DATA', data)
    } catch (error) {
      console.error(error)
    }
  })
  const settingsCardData = useMemo(
    () => [
      { title: 'Branch', value: data?.Branch },
      { title: 'Salesperson', value: data?.Salesperson },
      { title: 'F&I Manager', value: data?.FiUser },
      { title: 'Collector', value: data?.Collector },
      { title: 'Lead Source', value: data?.Advertisement },
      { title: 'Status', value: data?.Status },
      { title: 'Mileage', value: data?.CurrentMileage },
      {
        title: 'Delivered',
        value: (
          <Typography
            sx={{
              ml: 1,
              color: (theme) => (data?.IsDelivered ? theme.palette.success.main : theme.palette.error.main),
            }}
          >
            {data?.IsDelivered ? 'Yes' : 'No'}
          </Typography>
        ),
      },
    ],
    [data],
  )

  const insuranceCardData: any = useMemo(
    () =>
      data?.InsuranceID
        ? [
            { title: 'Name', value: data?.InsuranceName },
            { title: 'Agent', value: data?.InsuranceAgent },
            { title: 'Expires', value: convertDateToDisplay(data?.ExpirationDate) },
            { title: 'Policy #', value: data?.PolicyNumber },
            { title: 'CPI', value: data?.IsCPI ? 'Yes' : 'No' },
            { title: 'Phone', value: data?.InsurancePhone },
          ]
        : [],
    [data],
  )
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid container xs={12} md={8} spacing={3} direction={'column'}>
          <Grid xs={12}>
            <Card sx={{ height: '100%' }}>
              <CardHeader
                title='Buyer'
                action={
                  data?.CoBuyer ? (
                    ''
                  ) : (
                    <Button
                      size='small'
                      color='primary'
                      onClick={() =>
                        navigate(
                          appRoute.DealEditBuyer.replace(':dealID', dealID as string)
                            .replace(':contactID', data?.ContactID2?.toString() || 'add')
                            .replace(':contactType', 'co-buyer'),
                        )
                      }
                      startIcon={<Iconify icon='mingcute:add-line' />}
                    >
                      New Buyer
                    </Button>
                  )
                }
              />
              <Stack spacing={2.5} sx={{ p: 3 }}>
                <BuyerItem
                  variant='outlined'
                  name={data?.Buyer}
                  address={data?.BuyerAddressData?.[0]}
                  phoneNumber={data?.BuyerPhoneData?.[0]?.PhoneNumber}
                  buyerStatus={data?.BuyerStatus}
                  buyerStatusColor={data?.BuyerStatusColor}
                  action={
                    <IconButton
                      color={popover.open ? 'inherit' : 'default'}
                      onClick={(e) => {
                        popover.onOpen(e)
                        setUserSelected(data.ContactID1)
                      }}
                      sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                      <Iconify icon='eva:more-vertical-fill' />
                    </IconButton>
                  }
                  sx={{
                    p: 2.5,
                    borderRadius: 1,
                  }}
                />
                {data?.CoBuyer && (
                  <BuyerItem
                    variant='outlined'
                    name={data?.CoBuyer}
                    address={data?.CoBuyerAddressData?.[0]}
                    phoneNumber={data?.CoBuyerPhoneData?.[0]?.PhoneNumber}
                    buyerStatus={data?.CoBuyerStatus}
                    buyerStatusColor={data?.CoBuyerStatusColor}
                    action={
                      <IconButton
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          popover.onOpen(event)

                          setUserSelected(data?.ContactID2)
                          // console.log(buyer.id)
                        }}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                      >
                        <Iconify icon='eva:more-vertical-fill' />
                      </IconButton>
                    }
                    sx={{
                      p: 2.5,
                      borderRadius: 1,
                    }}
                  />
                )}
              </Stack>
            </Card>
          </Grid>

          {data?.IsHasTrade ? (
            <Grid xs={12}>
              <Card>
                <CardHeader
                  title='Trades'
                  action={
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        setAddNewModalOpen(true)
                      }}
                      startIcon={<Iconify icon='mingcute:add-line' />}
                    >
                      Trade
                    </Button>
                  }
                />
                <Label color={data?.TradeStatusColor} sx={{ m: 3, position: 'absolute', top: 3, left: 70 }}>
                  {data?.TradeStatus}
                </Label>
                <Stack spacing={2.5} sx={{ p: 3 }}>
                  {data?.TradeData?.map((trade: { StockNumber: string; CarDescription: string | undefined; VIN: string | undefined }) => (
                    <BuyerItem
                      key={trade.StockNumber}
                      variant='outlined'
                      name={trade.CarDescription}
                      phoneNumber={trade.VIN}
                      relationship={trade.StockNumber}
                      action={
                        <IconButton
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            setSelectedTrade(trade)
                            tradePopover.onOpen(event)
                          }}
                          sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                          <Iconify icon='eva:more-vertical-fill' />
                        </IconButton>
                      }
                      sx={{
                        p: 2.5,
                        borderRadius: 1,
                      }}
                    />
                  ))}
                </Stack>
              </Card>
            </Grid>
          ) : (
            <></>
          )}

          {data?.ProductData.length > 0 && (
            <Grid xs={12}>
              <Card>
                <CardHeader title='F&I Products' />
                <Label color={data?.ProductStatusColor} sx={{ m: 3, position: 'absolute', top: 3, left: 120 }}>
                  {data?.ProductStatus}
                </Label>
                <Stack spacing={2.5} sx={{ p: 3 }}>
                  {data?.ProductData?.map(
                    (product: { DealProductID: string | undefined; ProductLabel: string | undefined; ProductPrice: string | undefined }) => (
                      <BuyerItem
                        key={product.DealProductID}
                        variant='outlined'
                        name={product.ProductLabel}
                        phoneNumber={`Price: $${product.ProductPrice}`}
                        action={
                          <IconButton
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              setSelectedProduct(product)
                              productPopover.onOpen(event)
                            }}
                            sx={{ position: 'absolute', top: 8, right: 8 }}
                          >
                            <Iconify icon='eva:more-vertical-fill' />
                          </IconButton>
                        }
                        sx={{
                          p: 2.5,
                          borderRadius: 1,
                        }}
                      />
                    ),
                  )}
                </Stack>
              </Card>
            </Grid>
          )}
          <Grid xs={12}>
            <Card>
              <CardHeader
                title='Commissions'
                action={
                  <Button
                    size='small'
                    color='primary'
                    onClick={(e) => {
                      setSelectedCommission(undefined)
                      setAnchorEl(e.currentTarget)
                    }}
                    startIcon={<Iconify icon='mingcute:add-line' />}
                  >
                    Commission
                  </Button>
                }
              />
              <Stack spacing={2.5} sx={{ p: 3 }}>
                {data?.CommissionData?.map(
                  (commission: {
                    CommissionID: Key | null | undefined
                    Salesperson: string | undefined
                    AddedBy: string | undefined
                    CommissionAmount: string | undefined
                  }) => (
                    <CommissionItem
                      key={commission.CommissionID}
                      variant='outlined'
                      name={commission.Salesperson}
                      AddedBy={commission.AddedBy}
                      Amount={commission.CommissionAmount}
                      commissionStatus={data.CommissionStatus}
                      commissionStatusColor={data.CommissionStatusColor}
                      action={
                        <IconButton
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            setSelectedCommission(commission)
                            comissionPopover.onOpen(event)
                          }}
                          sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                          <Iconify icon='eva:more-vertical-fill' />
                        </IconButton>
                      }
                      sx={{
                        p: 2.5,
                        borderRadius: 1,
                      }}
                    />
                  ),
                )}
              </Stack>
            </Card>
          </Grid>
          <Grid xs={12}>
            <Card>
              <CardHeader
                title='Referrals'
                action={
                  <Button
                    size='small'
                    color='primary'
                    onClick={(e) => {
                      navigate(generatePath(appRoute.DealsReferralAddEdit, { dealID, referral: 'add' }))
                    }}
                    startIcon={<Iconify icon='mingcute:add-line' />}
                  >
                    Referral
                  </Button>
                }
              />
              <Stack spacing={2.5} sx={{ p: 3 }}>
                {data?.ReferralData?.map(
                  (referral: {
                    ReferralID: Key | null | undefined
                    Name: string | undefined
                    AddedBy: string | undefined
                    ReferralFee: string | undefined
                    ReferralStatus: string | undefined
                    ReferralStatusColor: string | undefined
                  }) => (
                    <ReferralItem
                      key={referral.ReferralID}
                      variant='outlined'
                      name={referral.Name}
                      AddedBy={referral.AddedBy}
                      Amount={referral.ReferralFee}
                      ReferralStatus={data.ReferralStatus}
                      ReferralStatusColor={data.ReferralStatusColor}
                      action={
                        <IconButton
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            setSelectedReferral(referral)
                            referralPopover.onOpen(event)
                          }}
                          sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                          <Iconify icon='eva:more-vertical-fill' />
                        </IconButton>
                      }
                      sx={{
                        p: 2.5,
                        borderRadius: 1,
                      }}
                    />
                  ),
                )}
              </Stack>
            </Card>
          </Grid>

          <Grid xs={12}>
            <Card>
              <CardHeader
                title='References'
                action={
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      data?.ContactID1
                        ? navigate(
                            generatePath(appRoute.DealsReferenceAddEdit, {
                              dealID,
                              contactID: data?.ContactID1,
                              referenceID: 'add',
                            }),
                          )
                        : dispatch(
                            enqueueNotification({
                              message: 'Must enter buyer information first.',
                              options: {
                                variant: 'error',
                              },
                            }),
                          )
                    }}
                    startIcon={<Iconify icon='mingcute:add-line' />}
                  >
                    Reference
                  </Button>
                }
              />
              <Stack spacing={2.5} sx={{ p: 3 }}>
                {data?.ReferenceData?.map(
                  (reference: {
                    ReferenceID: Key | null | undefined
                    Name: string | undefined
                    address: any
                    PhoneNumber: string | undefined
                    RelationshipType: string | undefined
                    IsVerified: boolean | undefined
                    id: any
                  }) => (
                    <BuyerItem
                      key={reference.ReferenceID}
                      variant='outlined'
                      name={reference.Name}
                      address={reference.address}
                      phoneNumber={reference.PhoneNumber}
                      relationship={reference.RelationshipType}
                      IsVerified={reference.IsVerified}
                      isReference={true}
                      action={
                        <IconButton
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            console.log(reference.id)
                            setSelectedReference(reference)
                            referencePopover.onOpen(event)
                          }}
                          sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                          <Iconify icon='eva:more-vertical-fill' />
                        </IconButton>
                      }
                      sx={{
                        p: 2.5,
                        borderRadius: 1,
                      }}
                    />
                  ),
                )}
              </Stack>
            </Card>
          </Grid>

          <Grid xs={12}>
            <Card sx={{ height: '100%' }}>
              <CardHeader
                title='Insurance'
                action={
                  <IconButton
                    onClick={() =>
                      data?.InsuranceID
                        ? navigate(
                            appRoute.DealsInsuranceEdit.replace(':dealID', dealID as string).replace(':insuranceID', data?.InsuranceID.toString()),
                          )
                        : navigate(appRoute.DealsInsuranceAdd.replace(':dealID', dealID as string))
                    }
                  >
                    <Iconify icon='solar:pen-bold' />
                  </IconButton>
                }
              />
              <Label color={data?.InsuranceStatusColor} sx={{ m: 3, position: 'absolute', top: 3, left: 95 }}>
                {data?.InsuranceStatus}
              </Label>
              <Box sx={{ p: 3 }} display={'flex'} flexDirection={'column'}>
                {insuranceCardData?.map(
                  (el: {
                    value: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | null | undefined
                    title:
                      | string
                      | number
                      | boolean
                      | ReactElement<any, string | JSXElementConstructor<any>>
                      | Iterable<ReactNode>
                      | ReactPortal
                      | null
                      | undefined
                  }) =>
                    el?.value && (
                      <Box display={'flex'} alignItems={'baseline'}>
                        <Typography sx={{ py: 1 }} variant='body2'>
                          {el?.title} :
                        </Typography>

                        {typeof el?.value === 'string' ? (
                          <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                            {el?.value}
                          </Typography>
                        ) : (
                          el?.value
                        )}
                      </Box>
                    ),
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid container xs={12} md={4} spacing={3} direction={'column'}>
          <Grid xs={12}>
            <Card sx={{ height: '100%' }}>
              <CardHeader
                title='Settings'
                action={
                  <IconButton onClick={() => setOpenSettingsModal(true)}>
                    <Iconify icon='solar:pen-bold' />
                  </IconButton>
                }
              />
              <Label color={data?.SettingsStatusColor} sx={{ m: 3, position: 'absolute', top: 3, left: 80 }}>
                {data?.SettingsStatus}
              </Label>
              <Box sx={{ p: 3 }} display={'flex'} flexDirection={'column'}>
                {settingsCardData.map(
                  (el) =>
                    el?.value && (
                      <Box display={'flex'} alignItems={'baseline'}>
                        <Typography sx={{ py: 1 }} variant='body2'>
                          {el?.title} :
                        </Typography>

                        {typeof el?.value === 'string' || typeof el?.value === 'number' ? (
                          <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                            {el?.value}
                          </Typography>
                        ) : (
                          el?.value
                        )}
                      </Box>
                    ),
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {data && (
        <PullCreditModal
          isPullCreditModalOpen={isPullCreditModalOpen}
          setIsPullCreditModalOpen={setIsPullCreditModalOpen}
          bureausData={bureausData}
          dealData={data.DealData}
        />
      )}

      <Dialog open={openSettingsModal} onClose={() => setOpenSettingsModal(false)}>
        <SettingsForm salesPersonID={data?.SalespersonID} managerID={data?.FiUserID} taskDeal={data} closeModal={() => setOpenSettingsModal(false)} />
      </Dialog>
      <DealsCommissionsAddEdit
        rowData={selectedCommission}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        type={selectedCommission ? 'edit' : 'add'}
        onSuccessCommissionDeal={onSuccessCommissionDeal}
        onSuccessEditCommissionDeal={onSuccessEditCommissionDeal}
      />

      <DealsProductsEdit
        open={productEdit}
        setOpen={setProductEdit}
        rowData={selectedProduct}
        onEditSuccess={() => {
          setProductEdit(false)
        }}
      />

      <CenteredModal
        open={addNewModalOpen}
        setOpen={setAddNewModalOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{
          minWidth: 300,
        }}
      >
        <>
          <Grid>
            <TextField value={vin} onChange={(e) => setVin(e.target.value)} name='vinAdd' label='VIN (Optional)' fullWidth />
          </Grid>
          <Grid container justifyContent={'flex-end'}>
            <Button
              variant='contained'
              onClick={handleAddNew}
              sx={{
                mt: 5,
              }}
            >
              Next <ArrowForwardIosIcon />
            </Button>
          </Grid>
        </>
      </CenteredModal>

      <ConfirmDialog
        open={deleteConfirmDialog}
        onOk={handleDelete}
        notificationTitle={'Delete Co-Buyer'}
        handleClose={() => setDeleteConfirmDialog(false)}
        notification={'Co-Buyer will be removed'}
      />

      <ConfirmDialog
        open={referenceDeleteConfirmDialog}
        onOk={handleReferenceDelete}
        handleClose={() => setReferenceDeleteConfirmDialog(false)}
        notificationTitle={'Delete Reference'}
        notification={'Reference will be removed'}
      />
      <ConfirmDialog
        open={tradeDeleteConfirmDialog}
        onOk={handleTradeDelete}
        handleClose={() => setTradeDeleteConfirmDialog(false)}
        notificationTitle={'Delete Trade'}
        notification={'Trade will be removed'}
      />
      <ConfirmDialog
        open={commissionDeleteModal}
        onOk={handleComissionDelete}
        handleClose={() => setCommissionDeleteModal(false)}
        notification={'Commission will be removed.'}
      />
      <ConfirmDialog
        open={referralDeleteModal}
        onOk={handleReferralDelete}
        handleClose={() => setReferralDeleteModal(false)}
        notification={'Remove referral from deal?'}
      />
      <CustomPopover open={tradePopover.open} onClose={tradePopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            navigate(appRoute.DealsTradeEdit.replace(':dealID', dealID as string).replace(':tradeID', selectedTrade.TradeID?.toString() || 'add'))
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setTradeDeleteConfirmDialog(true)
            tradePopover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
      <CustomPopover open={productPopover.open} onClose={productPopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            setProductEdit(true)
            productPopover.onClose()
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
      </CustomPopover>
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            navigate(
              appRoute.DealEditBuyer.replace(':dealID', dealID as string)
                .replace(':contactID', userSelected?.toString() || 'add')
                .replace(':contactType', `${userSelected === data?.ContactID2 ? 'co-buyer' : 'buyer'}`),
            )
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        {userSelected === data?.ContactID2 && (
          <MenuItem
            onClick={() => {
              setDeleteConfirmDialog(true)
              popover.onClose()
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon='solar:trash-bin-trash-bold' />
            Delete
          </MenuItem>
        )}
      </CustomPopover>
      <CustomPopover open={referencePopover.open} onClose={referencePopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={() =>
            navigate(
              generatePath(appRoute.DealsReferenceAddEdit, {
                dealID,
                contactID: data.ContactID1,
                referenceID: `${seletedReference.ReferenceID}`,
              }),
            )
          }
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setReferenceDeleteConfirmDialog(true)
            referencePopover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
      <CustomPopover open={comissionPopover.open} onClose={comissionPopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(e) => {
            setAnchorEl(e.currentTarget)
            comissionPopover.onClose()
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCommissionDeleteModal(true)
            comissionPopover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
      <CustomPopover open={referralPopover.open} onClose={referralPopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(e) => {
            navigate(
              generatePath(appRoute.DealsReferralAddEdit, {
                dealID,
                referral: selectedReferral?.ReferralID,
              }),
            )
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setReferralDeleteModal(true)
            referralPopover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
    </FormProvider>
  )
}
