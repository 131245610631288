import { convertStringToDate } from '../../../../../../utils/dateUtils'
import { formatNumber } from '../../../../../../utils/general'
import removeMask from '../../../../../../utils/removeMask'


export const transformToRequestValues = (
  values?: any,
  branchID?: any,
  dealID?: any,
  carID?: any,
  contactID1?: any,
  accountDueAmountData?: any,
  distributions?: any,
) => {
  // Map the distributions array to the required format
  const distributionData =
    distributions?.map((dist: any) => ({
      DistributionType: dist.Type?.toString(),
      AmountApplied: removeMask(dist.AmountAdd?.toString()),
      LateChargeCollected: removeMask(dist.LateFee?.toString()),
    })) || []

  const distributionDataDownpayment = [
    {
      DistributionType: removeMask(values?.TypeDistribution?.toString()),
      AmountApplied: removeMask(values?.Amount?.toString()),
      LateChargeCollected: '0',
    },
  ]

  return {
    branchID: parseInt(branchID),
    paymentID: null,
    isReversal: false,
    isChargeback: false,
    chargebackFee: '0',
    chargebackBankCoaID: null,
    isRestrictCheckACH: false,
    paymentDate: values?.EffectiveDate ? convertStringToDate(values?.EffectiveDate) :  null,
    dealID: dealID as number,
    carID: carID as number,
    contactID: contactID1 as number,
    paymentType: values?.TypeSetup,
    customOffsetCoaID: null,
    paymentMethodID: values?.Method,
    paymentFormID: values?.Form,
    originalSuspensePaymentID: null,
    checkTypeID: values?.CheckType,
    referenceNumber: values?.Form === 3 ? values?.CheckNumber : values?.Form === 5 ? values?.Last4ofCreditCard : null,
    creditCardTypeID: values?.CreditType,
    cardExpiration: null,
    authorizationNumber: values?.Form === 5 ? values?.AuthorizationNumber : null,
    transactionAmount: removeMask(values?.TotalPayment as string) || null,
    paymentTendered: removeMask(values?.AmountTendered),
    changeDue: removeMask(formatNumber(values?.Change as string)) || null,
    writeOffAmount: '0' ,
    writeOffChartOfAccountID: null,
    processingFee: removeMask(values?.ProcessingFee),
    processingFeeExpense: '0',
    distributionData: values?.TypeSetup?.toLowerCase() === 'downpayment' ? distributionDataDownpayment : distributionData,
    lateChargeCount: accountDueAmountData?.LateChargeCount,
    interestDue: accountDueAmountData?.InterestDue,
    interestRebate: accountDueAmountData?.InterestRebate,
    insuranceRebate: accountDueAmountData?.ProductRebates,
    pickupLateChargeDue: accountDueAmountData?.PickupLateChargeDue,
    sideNoteLcDue: '0',
    sideNoteAllocationData: [] ,
    isPrincipalOnly: false,
    paymentNote: values?.Comment,
    payeeName: null,
    sideNoteID: null,
    sideNoteFirstDueDate: null,
    isPayoff: false,
    creditCode: null,
    specialComment: null,
    indicator: null,
    isRefinance: false,
    newDealID: null,
    isExtension: false, // FOR NOW
    extensionDate: null,
    newDueDay1: null,
    newDueDay2: null,
    isRemovePaymentTowardAdvance: false,
    reversalReapplyPaymentID: null,
    isSystemGenerated: false,
  }
}
