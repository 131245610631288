import Modal from '@mui/material/Modal'
import { Form } from 'react-final-form'
import { Dialog, DialogActions, DialogContent, DialogTitle, generateUtilityClasses, Grid, Paper, Step, StepLabel, Stepper } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { styled } from '@mui/system'
import DealsCard from './components/DealsCard'
import PayeeCard from './components/PayeeCard'
import {
  DropdownResponse,
  ParsedDropdownResponse,
  selectDropdownByName,
  useAddCarPaymentMutation,
  useGetTimeMutation,
  useLazyGetCarPaymentDetailsQuery,
} from '../../../../services'
import { useLocation, useParams } from 'react-router-dom'
import { AccountsDropdownResult, TPaymentCheckType, UspCarDealViewResult } from '../../../../models'
import Typography from '@mui/material/Typography'
import validateFormValues from '../../../../utils/validateForm'
import { inventoryAddDepositValidation } from '../../../../validation/inventoryAddDepositValidation'
import SetupCard from './components/SetupCard'
import CheckCard from './components/CheckCard'
import CreditCard from './components/CreditCard'
import DepositCard from './components/DepositCard'
import FinalizeCard from './components/FinalizeCard'
import PaymentNoteCard from './components/PaymentNoteCard'
import { transformToRequestValues } from './transform'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CustomCircular } from '../../../../ui'
import { setCars } from '../../../../redux/slices/carsSlice'
import { convertDateToString } from '../../../../utils/dateUtils'
import { fetchCurrentDateTimeFromServer } from '../../../../utils/general'

const classes = generateUtilityClasses('form', ['root', 'formItem', 'formTable'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    backgroundColor: theme.palette.background.secondary,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '900px',
    boxShadow: 24,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.formTable}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
}))

interface Props {
  handleClose: () => void
  openModal: boolean
  getCarPaymentTableData: (e: any) => void
  getCar: (e: any) => void
  carData: any
  carIsSuccess: boolean
  setFormChanged: Dispatch<SetStateAction<boolean>>
}
const steps = ['Deals', 'Deposit', 'Payment']

const AddDepositModal = ({ setFormChanged, carIsSuccess, carData, handleClose, openModal, getCarPaymentTableData, getCar }: Props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { carID } = useParams()
  const [selectedPendingRecord, setSelectedPendingRecord] = useState({})
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const [initialValues, setInitialValues] = useState({})
  const [addCarPayment, { isSuccess: addCarPaymentIsSuccess }] = useAddCarPaymentMutation()
  const [getCarPayment, { data: carPaymentData, isLoading: carPaymentDataIsLoading, isSuccess: carPaymentDataIsSuccess }] =
    useLazyGetCarPaymentDetailsQuery()
  const paymentForm = useSelector((state) => selectDropdownByName(state, 'PaymentForm'))
  const paymentMethod = useSelector((state) => selectDropdownByName(state, 'PaymentMethod'))
  const paymentCheckType = useSelector((state) => selectDropdownByName(state, 'PaymentCheckType'))
  const paymentCreditCardType = useSelector((state) => selectDropdownByName(state, 'PaymentCreditCardType'))
  const [getTime] = useGetTimeMutation()

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    if (carPaymentData) {
      fetchCurrentDateTimeFromServer(getTime).then((currentDate) => {
        setInitialValues({
          ApplicantAccountType: 'individual',
          EffectiveDate: convertDateToString(currentDate as string) ?? '',
          ProcessingFee: '0',
          Amount: '0',
        })
      })
    }
  }, [carPaymentData])

  useEffect(() => {
    if (openModal) {
      getCarPayment({
        carId: carID as string,
      })
    }
  }, [openModal])

  useEffect(() => {
    if (addCarPaymentIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      if (location.pathname.includes(`/inventory/edit/${carID}/payments`)) {
        if (getCarPaymentTableData) {
          getCarPaymentTableData({
            carId: carID as string,
            uspCarPaymentView: {
              isIncludeReversals: false,
            },
          })
        }
      }
      getCar({ carId: carID as string })

      setActiveStep(0)
      handleClose()
    }
  }, [addCarPaymentIsSuccess, carID])

  if (carIsSuccess) {
    dispatch(setCars(carData))
  }

  const handleSubmit = (values?: any) => {
    setFormChanged(false)
    addCarPayment({
      carId: carID?.toString() as string,
      uspCarPayment: transformToRequestValues(values, checkID, creditCardID),
    })
  }

  const checkID = paymentForm?.ReturnData?.find((item) => item?.PaymentForm === 'Check')?.ID ?? null
  const creditCardID = paymentForm?.ReturnData?.find((item) => item?.PaymentForm === 'Credit Card')?.ID ?? null
  const isLoading = useMemo(() => carPaymentDataIsLoading, [carPaymentDataIsLoading])

  return (
    <Dialog open={openModal} onClose={handleClose}>
      <DialogTitle />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validateFormValues(
          inventoryAddDepositValidation(carPaymentData as UspCarDealViewResult[], checkID, creditCardID, selectedPendingRecord),
        )}
      >
        {({ handleSubmit, values, errors }: any) => (
          <DialogContent>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {}
                const labelProps: {
                  optional?: React.ReactNode
                } = {}
                if (isStepSkipped(index)) {
                  stepProps.completed = false
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>

            {activeStep === steps?.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid container spacing={2} py={3}>
                  {activeStep === 0 && (
                    <>
                      {carPaymentData && carPaymentData?.length >= 1 ? (
                        <Grid item xs={12}>
                          <DealsCard
                            setSelectedPendingRecord={setSelectedPendingRecord}
                            carPaymentDataIsSuccess={carPaymentDataIsSuccess}
                            carPaymentData={carPaymentData as UspCarDealViewResult[]}
                          />
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <PayeeCard selectedPendingRecord={selectedPendingRecord} />
                      </Grid>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      {paymentForm && paymentMethod && (
                        <Grid item xs={12}>
                          <SetupCard
                            paymentForm={paymentForm?.ReturnData as ParsedDropdownResponse[]}
                            paymentMethod={paymentMethod?.ReturnData as ParsedDropdownResponse[]}
                          />
                        </Grid>
                      )}
                      {values?.Form === checkID && paymentCheckType && (
                        <Grid item xs={12}>
                          <CheckCard checkType={paymentCheckType?.ReturnData as ParsedDropdownResponse[]} />
                        </Grid>
                      )}
                      {values?.Form === creditCardID && paymentCreditCardType && (
                        <Grid item xs={12}>
                          <CreditCard cardType={paymentCreditCardType?.ReturnData as ParsedDropdownResponse[]} />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <DepositCard />
                      </Grid>
                    </>
                  )}

                  {activeStep === 2 && (
                    <>
                      <Grid item xs={12}>
                        <FinalizeCard />
                      </Grid>
                      <Grid item xs={12}>
                        <PaymentNoteCard />
                      </Grid>
                    </>
                  )}
                </Grid>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 4, alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Button tabIndex={2} variant='contained' color='warning' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                    Back
                  </Button>
                  <Button
                    onClick={() => {
                      handleClose()
                      setActiveStep(0)
                    }}
                    tabIndex={1}
                    variant='contained'
                    color='error'
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      (activeStep === 0 && errors?.Pending) ||
                      (activeStep === 0 && errors?.ApplicantFirstName) ||
                      (activeStep === 0 && errors?.ApplicantLastName) ||
                      (activeStep === 0 && errors?.ApplicantBusinessName) ||
                      (activeStep === 1 && errors?.Amount) ||
                      (activeStep === 1 && errors?.CheckType) ||
                      (activeStep === 1 && errors?.CheckNumber) ||
                      (activeStep === 1 && errors?.CreditType) ||
                      (activeStep === 1 && errors?.Last4ofCreditCard) ||
                      (activeStep === 1 && errors?.AuthorizationNumber) ||
                      (activeStep === 1 && errors?.Method) ||
                      (activeStep === 1 && errors?.Form)
                    }
                    tabIndex={0}
                    variant='contained'
                    type='button'
                    onClick={activeStep === steps?.length - 1 ? handleSubmit : handleNext}
                  >
                    {activeStep === steps?.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </DialogContent>
        )}
      </Form>
      <CustomCircular open={isLoading} />
      <DialogActions />
    </Dialog>
  )
}

export default AddDepositModal
