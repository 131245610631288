import { Card, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { motion } from 'framer-motion'

const classes = generateUtilityClasses('Paper', ['root', 'title', 'subTitle'])

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.title}`]: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  [`& .${classes.subTitle}`]: {
    fontSize: '15px',
    fontWeight: 500,
  },
}))

interface Props {
  title: string
  subTitle: string
}

const InventoryCardTwo = ({ title, subTitle }: Props) => {
  return (
    <motion.div
      className='box'
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.25,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      style={{
        height: '100%',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
      }}
    >
      <StyledCard
        sx={{
          '&.MuiPaper-root': {
            height: '100%',
          },
        }}
        className={classes.root}
      >
        <Grid item>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.subTitle}>{subTitle}</Typography>
        </Grid>
      </StyledCard>
    </motion.div>
  )
}

export default InventoryCardTwo
